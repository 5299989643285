import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    globalVar: '这是我的全局变量'
  },
  getters: {
    getGlobalVar: state => {
      return state.globalVar
    }
  },
  mutations: {
    setGlobalVar(state, newValue) {
      state.globalVar = newValue
    }
  },
  actions: {
    updateGlobalVar({ commit }, newValue) {
      commit('setGlobalVar', newValue)
    }
  },
  modules: {
  }
})

<template>
    <div id="aboutUs" class="aboutUs">
      <div class="floating-navbar">
        <div>
          <img src="../../assets/companyLOGO.png">
        </div>
        <ul>
          <li v-for="(item, index) in navItems" :key="index">
            <a :href="item.link" @click.prevent="scrollToSection('#section'+(index+1))">{{ item.text }}</a>
          </li>
        </ul>
      </div>
      <div id="section1" class="card_class">
        <div class="left_text_class">
          企业概述
        </div>
        <div class="right_text_mar_class right_text1_class">
          {{ enterpriseOverview1 }}
        </div>
        <div class="right_text2_class">
          {{ enterpriseOverview2 }}
        </div>
      </div>
      <div id="section2" class="philosophy_class">
        <div class="left_text_class">
          企业理念
        </div>
        <div class="philosophy_div_text_class">
          <div class="philosophy_text_class">
            {{ philosophy1 }}
          </div>
          <div class="philosophy_text_class">
            {{ philosophy2 }}
          </div>
          <div class="philosophy_text_class">
            {{ philosophy3 }}
          </div>
          <div class="philosophy_text_class">
            {{ philosophy4 }}
          </div>
          <div class="philosophy_text_class">
            {{ philosophy5 }}
          </div>
        </div>
      </div>
      <div id="section3" class="developmentHistory_class">
        <div class="left_text_class">
          企业愿景
        </div>
        <div class="right_text_mar_class right_text1_class">
          {{ hopeVision }}
          <!-- <el-tabs tabPosition="right">
            <el-tab-pane label="2012">
              <div class="developmentHistory_text_year_class">
                2012
              </div>
              <div class="developmentHistory_text_introduce_class">
                大连业务逐渐展开
              </div>
            </el-tab-pane>
            <el-tab-pane label="2015">
              <div class="developmentHistory_text_year_class">
                2015
              </div>
              <div class="developmentHistory_text_introduce_class">
                大连华腾科技正式成立
              </div>
            </el-tab-pane>
            <el-tab-pane label="2018">
              <div class="developmentHistory_text_year_class">
                2018
              </div>
              <div class="developmentHistory_text_introduce_class">
                长春事务所正式成立
              </div>
            </el-tab-pane>
            <el-tab-pane label="2019">
              <div class="developmentHistory_text_year_class">
                2019
              </div>
              <div class="developmentHistory_text_introduce_class">
                公司骨干团队得到充实完备
              </div>
            </el-tab-pane>
            <el-tab-pane label="2021">
              <div class="developmentHistory_text_year_class">
                2021
              </div>
              <div class="developmentHistory_text_introduce_class">
                海南乾助公司成立
              </div>
            </el-tab-pane>
            <el-tab-pane label="2022">
              <div class="developmentHistory_text_year_class">
                2022
              </div>
              <div class="developmentHistory_text_introduce_class">
                大连树新智能公司成立
              </div>
            </el-tab-pane>
          </el-tabs> -->
        </div>
      </div>
      <div id="section4" class="qualifications_class">
        <div class="left_text_class">
          企业资质
        </div>
        <div class="qualifications_img_class">
          <img src="../../assets/软著展示.png">
        </div>
        <div class="aptitude">
          {{ aptitude }}
          <!-- <div class="qualifications_img_class">
            <img src="../../assets/qualifications_3A.png">
          </div>
          <div class="qualifications_img_class">
            <img src="../../assets/qualifications_软著.png">
          </div> -->
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'AboutUs',
  components: {
  },
  watch: {
    '$route'(to, from) {
      // 获取目标路由的路径
      const toPath = to.path
      // 获取目标路由的参数
      const toTypeNumber = to.query.type
      // // 获取来源路由的路径
      // const fromPath = from.path
      // // 获取来源路由的参数
      // const fromTypeNumber = from.query.type
      if (toPath === '/AboutUs') {
        const targetElement = document.querySelector('#section' + toTypeNumber)
        if (targetElement) {
          window.scrollTo({
            top: targetElement.offsetTop - 60,
            behavior: 'smooth'
          })
        }
      }
    }
  },
  data() {
    return {
      navItems: [
        { text: '企业概述', link: '#section1' },
        { text: '企业理念', link: '#section2' },
        { text: '企业愿景', link: '#section3' },
        { text: '企业资质', link: '#section4' }
        // Add more navigation items if needed
      ],
      // 企业概述
      enterpriseOverview1: '海南乾助科技有限公司，是一家以提供软件项目定制化研发与软件项目整体化解决方案为主的高科技类企业。公司成立于2021年10月份，主营业务包括产品研发、咨询服务、人力资源服务等，服务的客户群体主要为：政府机关、科研院所、涉密单位，先后承接过海洋、农业、医疗等相关领域的项目。公司在人工智能、云计算、大数据、区块链等技术领域持续不断的投入科研力量，积累了大量的行业经验和人才储备，未来将与垂直产业深度结合，在新质生产力的推动发展中，以客户为中心持续的创造更大社会价值。',
      enterpriseOverview2: '作为一家以技术为核心的企业，海南乾助科技有限公司始终秉持“创新、专业、共赢”的理念，为客户提供定制化的软件产品和卓越的技术服务。在软件开发领域，公司拥有丰富的项目经验，涉及虚拟现实、智慧海洋、智慧农业等多个领域，为客户提供全面、高效的软件解决方案。在技术服务方面，公司提供包括系统维护、技术支持、IT咨询等在内的一系列服务，助力客户实现业务发展。',
      // 总经理致词
      philosophy1: '诚，物质终始、无物不诚',
      philosophy2: '信，立身之本、专一不移',
      philosophy3: '敬，不解于位、治世肃恭',
      philosophy4: '勤，积善成德、业精于勤',
      philosophy5: '精，专业凸显、差异优势',
      hopeVision: '我们的愿景是成为全球领先的软件开发和技术服务提供商，以创新的技术和卓越的服务推动客户的成功。我们致力于通过不断的技术创新和人才培养，为客户提供最优质的产品和服务，成为客户最信赖的合作伙伴。我们的目标是成为行业的领导者，引领行业的发展方向，为社会的进步和发展做出贡献。',
      socialResponsibility: '作为一家根植于海南的企业，海南乾助科技有限公司积极承担并践行社会责任，致力于推动地区经济的发展和社会进步。我们通过提供高质量的软件开发和技术服务，帮助本地企业提升效率，增强竞争力，从而促进海南自由贸易港的建设和发展。同时，我们关注海南的生态环境保护，支持绿色技术的研发和应用，努力实现企业发展与环境保护的双赢。海南乾助将持之以恒以实际行动回馈社会，展现海南企业的良好形象和社会责任感。',
      aptitude: '公司已获取三十余项软件著作权'
    }
  },
  mounted() {
    // 监听鼠标滚动事件
    // window.addEventListener('mousewheel', this.debounce(this.handleScroll), false)

    const type = this.$route.query.type
    const targetElement = document.querySelector('#section' + type)
    console.log('type', type)
    console.log('targetElement', targetElement)
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop - 50,
        behavior: 'smooth'
      })
    }
  },
  methods: {
    scrollToSection(selector) {
      const targetElement = document.querySelector(selector)
      if (targetElement) {
        window.scrollTo({
          top: targetElement.offsetTop - 50,
          behavior: 'smooth'
        })
      }
    },
    // 鼠标滚动事件
    handleScroll(e) {
      const direction = e.deltaY > 0 ? 'down' : 'up'
      if (direction === 'down' && e.deltaY >= 100) {
        let index = this.arrtab.indexOf(this.activeName)
        if (index < this.arrtab.length - 1) {
          index++
          console.log(index)
          this.activeName = this.arrtab[index]
        }
      }
      if (direction === 'up' && e.deltaY <= -100) {
        let index = this.arrtab.indexOf(this.activeName)
        if (index >= 1) {
          index--
          console.log(index)
          this.activeName = this.arrtab[index]
        }
      }
      console.log(this.activeName)
    },
    debounce(func, wait = 100, immediate = true) {
      let timer
      return (...args) => {
        const context = this
        if (timer) clearTimeout(timer)
        if (immediate) {
          const callNow = !timer
          timer = setTimeout(() => {
            timer = null
          }, wait)
          if (callNow) func.apply(context, args)
        } else {
          timer = setTimeout(() => {
            func.apply(context, args)
          }, wait)
        }
      }
    }
  }
}
</script>
<style lang="scss">
  // pc适配宽度
  @media screen and (min-width: 1220px) {
    .aboutUs {
      background-color: #f2f2f2;
    }
    .card_class {
      min-height: 600px;
      padding: 1px;
      height: 90vh;
      background-color: rgba(255, 255, 255, 0.808);
      background-blend-mode: lighten;
      background-image: url(../../assets/企业介绍.jpg);
      background-size: cover;
      background-position: center center;
      // margin-top: 60px;
    }
    .left_text_class {
      // float: left;
      width: 20vw;
      margin-top: 10vh;
      font-size: 40px;
      font-weight: 600;
      margin-left: 184px;
    }
    .right_text_mar_class {
      // margin-top: 150px !important;
    }
    .right_text1_class {
      // float: left;
      margin-left: 30vw;
      width: 50vw;
      color: rgb(59, 59, 59);
      text-indent: 40px;
      line-height: 40px;
      margin-top: 10vh;
      font-size: 25px;
      font-weight: 600;
      text-align: left;
    }
    .right_text2_class {
      width: 50vw;
      margin-left: 30vw;
      color: rgb(59, 59, 59);
      text-indent: 40px;
      line-height: 40px;
      font-size: 25px;
      font-weight: 600;
      text-align: left;
    }
    .philosophy_class {
      background-image: url(../../assets/philosophy.jpg);
      padding: 1px;
      height: 90vh;
      background-size: cover;
      background-position: center center;
      min-height: 580px;
    }
    .philosophy_div_text_class {
      // float: left;
      // margin-top: 130px;
      // margin-left: 30vw;
    }
    .philosophy_text_class {
      color: rgb(59, 59, 59);
      text-indent: 40px;
      line-height: 40px;
      margin-top: 20px;
      font-size: 30px;
      font-weight: 600;
      margin-left: 40vw;
      // text-align: left;
    }
    .qualifications_class {
      background-image: url(../../assets/企业资质.png);
      background-color: rgba(255, 255, 255, 0.4);
      background-size: cover;
      background-position: center center;
      background-blend-mode: lighten;
      height: 90vh;
      min-height: 600px;
      padding: 1px;
      overflow: hidden;
      .qualifications_div_class {
        display: flex;
        // margin-top: 100px;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
      }
      .qualifications_img_class {
          // width: 31%;
          // margin-left: 3vw;
          // margin-top: 10vh;
          img{
            width: 40%;
            margin-top: 3vh;
            // width: 100%;
          }
      }
      .aptitude {
        margin: 0 auto;
        /* margin-left: 30vw; */
        width: 50vw;
        color: #3b3b3b;
        text-indent: 40px;
        line-height: 40px;
        margin-top: 5vh;
        font-size: 25px;
        font-weight: 600;
      }
    }
    .developmentHistory_class {
      background-image: url(../../assets/企业愿景.png);
      background-color: rgba(255, 255, 255, 0.4);
      background-blend-mode: lighten;
      background-size: cover;
      background-position: center center;
      height: 90vh;
      padding: 1px;
      min-height: 600px;
    }
    .developmentHistory_div_class {
      float: right;
      margin-top: 80px;
      // margin-left: 45vw;
      margin-right: 20vw;
      .el-tabs__item {
        font-size: 30px;
      }
      .el-tabs__item:hover {
        color: black;
      }
      .el-tabs__item.is-active {
        color: black;
        font-size: 32px;
      }
      .el-tabs__active-bar {
        background-color: black;
      }
    }
    .developmentHistory_text_year_class {
      font-size: 50px;
      font-weight: 600;
    }
    .developmentHistory_text_introduce_class {
      font-size: 20px;
      margin-top: 20px;
    }
    .floating-navbar {
      position: fixed;
      top: 40%;
      right: 0vw;
      // width: 100%;
      // background-color: #333;
      padding: 0px 3vw;
    }
    .floating-navbar ul {
      list-style: none;
      margin: 0;
      padding: 0;
      // display: flex;
      // justify-content: center;
    }
    .floating-navbar li {
      margin: 0 15px;
    }
    .floating-navbar a {
      text-decoration: none;
      font-size: 16px;
      line-height: 40px;
      color: #3b3b3b;
      // padding-left: 17px;
      margin-right: 2px;
      border-left: 2px solid transparent;
    }
    .floating-navbar a:hover {
      cursor: pointer;
      border-left: 2px solid #666;
    }
  }
  // 手机、平板适配宽度
  @media screen and (max-width: 1219px) {
    .card_class {
      min-height: 630px;
      background-color: rgba(255, 255, 255, 0.808);
      background-blend-mode: lighten;
      background-image: url(../../assets/企业介绍.jpg);
      background-size: cover;
      background-position: center center;
      padding-top: 70px;
    }
    .left_text_class {
      font-size: 20px;
      font-weight: 500;
    }
    .right_text_mar_class {
      margin-top: 30px !important;
    }
    .right_text1_class {
      color: rgb(59, 59, 59);
      text-indent: 40px;
      line-height: 40px;
      font-size: 20px;
      font-weight: 600;
      text-align: left;
      padding: 20px;
    }
    .right_text2_class {
      color: rgb(59, 59, 59);
      text-indent: 40px;
      line-height: 40px;
      font-size: 20px;
      font-weight: 600;
      text-align: left;
      padding: 20px;
    }
    .philosophy_class {
      background-color: rgba(255, 255, 255, 0);
      background-blend-mode: lighten;
      background-image: url(../../assets/philosophy.jpg);
      background-size: cover;
      background-position: center center;
      // min-height: 500px;
      // padding-top: 40px;
      padding: 30px 0px;
    }
    .philosophy_div_text_class {
      margin-top: 50px;
    }
    .philosophy_text_class {
      color: rgb(59, 59, 59);
      line-height: 40px;
      margin-top: 20px;
      font-size: 25px;
      font-weight: 600;
      text-align: center;
    }
    .floating-navbar {
      display: none;
    }
    .developmentHistory_class {
      background-image: url(../../assets/企业愿景.png);
      padding: 30px 0px;
    }
    .developmentHistory_text_year_class {
      margin-top: 20px;
      line-height: 40px;
      font-size: 20px;
      font-weight: 600;
    }
    .developmentHistory_text_introduce_class {
      margin-top: 20px;
      line-height: 40px;
      font-size: 20px;
      font-weight: 600;
    }
    .qualifications_class {
      background-image: url(../../assets/企业资质.png);
      background-color: rgba(255, 255, 255, 0.4);
      background-size: cover;
      background-position: center center;
      background-blend-mode: lighten;
      padding: 30px 0px;
      // height: 25vh;
      // min-height: 600px;
      // overflow: hidden;
      .qualifications_div_class {
        display: flex;
        // margin-top: 100px;
        margin-top: 5vh;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        // .qualifications_img_class {
        //   width: 40%;
        //   margin-left: 3vw;
        //   // margin-top: 10vh;
        //   img{
        //     width: 100%;
        //   }
        // }
      }
      .qualifications_img_class {
          // width: 31%;
          // margin-left: 3vw;
          // margin-top: 10vh;
          img{
            width: 80%;
            margin-top: 3vh;
            // width: 100%;
          }
      }
      .aptitude {
        margin: 0 auto;
        /* margin-left: 30vw; */
        // width: 50vw;
        color: #3b3b3b;
        // text-indent: 40px;
        line-height: 40px;
        margin-top: 1vh;
        font-size: 25px;
        font-weight: 600;
      }
    }
}
</style>

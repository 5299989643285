<template>
  <div class="product_div_class">
    <div class="img_position">
      <div class="img_box">
        <img :src="productBody.backBigImg" class="product_img_class">
      </div>
    </div>
    <div v-if="productBody.name === '图像识别拍摄设备'">
      <div class="productInfo_position">
        <div class="pruduct_box">
          <van-swipe v-if="smallScreen" class="my-swipe1a" :autoplay="3000" indicator-color="black" lazy-render>
            <van-swipe-item v-for="item in list" :key="item">
              <div class="background-container1a" :style="{ backgroundImage: 'url(' + item.imgUrl + ')' }"></div>
            </van-swipe-item>
          </van-swipe>
          <el-carousel v-else autoplay='false' type="fade" height="500px" arrow="always">
            <el-carousel-item v-for="(item, index) in list" :key="index" >
              <img :src="item.imgUrl" alt="" style="width: 50vw">
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="productInfo_text">
        <div>
          <p class="t2">分辨率、视场角与追踪距离的完美结合</p>
        </div>
      </div>
      <div class="productInfo_part1">
        <div class="container1">
          <div class="fletbox">
            <img :src="productBody.src1">
          </div>
          <div class="rightbox">
            <p class="t2">{{ productBody.title1 }}</p>
            <p class="t3">{{ productBody.text1 }}</p>
          </div>
        </div>
      </div>
      <div class="productInfo_part2">
        <div class="container2">
          <div class="rightbox">
            <p class="t2">{{ productBody.title2 }}</p>
            <p class="t3">{{ productBody.text2 }}</p>
          </div>
          <div class="fletbox">
            <img :src="productBody.src2">
          </div>
        </div>
      </div>
      <div class="productInfo_part1">
        <div class="container1">
          <div class="fletbox">
            <img :src="productBody.src3">
          </div>
          <div class="rightbox">
            <p class="t2">{{ productBody.title3 }}</p>
            <p class="t3">{{ productBody.text3 }}</p>
          </div>
        </div>
      </div>
      <div class="productInfo_part2">
        <div class="container2">
          <div class="rightbox">
            <p class="t2">{{ productBody.title4 }}</p>
            <p class="t3">{{ productBody.text4 }}</p>
          </div>
          <div class="fletbox">
            <img :src="productBody.src4">
          </div>
        </div>
      </div>
      <div class="productInfo_part1">
        <div class="container1">
          <div class="fletbox">
            <img :src="productBody.src5">
          </div>
          <div class="rightbox">
            <p class="t2">{{ productBody.title5 }}</p>
            <p class="t3">{{ productBody.text5 }}</p>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="productBody.name === '步行辅助设备'">
      <div class="productBody1">
        <div class="img_src1">
          <img :src="productBody.src1">
        </div>
        <div class="productBody1_table">
          <table>
            <tbody>
              <tr>
                <td>重量</td>
                <td>6.5kg（包含鞋子和电池的重量）</td>
              </tr>
              <tr>
                <td>驱动方式</td>
                <td>电机*2</td>
              </tr>
              <tr>
                <td>电池盒</td>
                <td>锂离子电池</td>
              </tr>
              <tr>
                <td>充电一次可使用的时间</td>
                <td>大约2小时（步行、弯腰等姿势）</td>
              </tr>
              <tr>
                <td>适合身高</td>
                <td>设定身高±5cm的范围<br>例：M号&nbsp;设定身高&nbsp;&nbsp;170cm</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="productBody1_text">
          <div>
            <p class="productBody1_title1">{{ productBody.title1 }}</p>
            <p class="productBody1_text1">{{ productBody.text1 }}</p>
          </div>
          <div>
            <p class="productBody1_title2">{{ productBody.title2 }}</p>
            <p class="productBody1_text2">{{ productBody.text2 }}</p>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="productBody.name === 'VR体感动作捕捉设备'">
      <div class="productInfo_part1">
        <div class="container1">
          <div class="fletbox">
            <img :src="productBody.src1">
          </div>
          <div class="rightbox">
            <p class="t2">{{ productBody.title1 }}</p>
            <p class="t3">{{ productBody.text1 }}</p>
          </div>
        </div>
      </div>
      <div class="productInfo_part2">
        <div class="container2">
          <div class="rightbox">
            <p class="t2">{{ productBody.title2 }}</p>
            <p class="t3">{{ productBody.text2 }}</p>
          </div>
          <div class="fletbox">
            <img :src="productBody.src2">
          </div>
        </div>
      </div>
      <div class="productInfo_part1">
        <div class="container1">
          <div class="fletbox">
            <img :src="productBody.src3">
          </div>
          <div class="rightbox">
            <p class="t2">{{ productBody.title3 }}</p>
            <p class="t3">{{ productBody.text3 }}</p>
          </div>
        </div>
      </div>
      <div class="productInfo_part2">
        <div class="container2">
          <div class="rightbox">
            <p class="t2">{{ productBody.title4 }}</p>
            <p class="t3">{{ productBody.text4 }}</p>
          </div>
          <div class="fletbox">
            <img :src="productBody.src4">
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="productInfo_part1">
        <div class="container1">
          <div class="fletbox">
            <img :src="productBody.src1">
          </div>
          <div class="rightbox">
            <p class="t2">{{ productBody.title1 }}</p>
            <p class="t3">{{ productBody.text1 }}</p>
          </div>
        </div>
      </div>
      <div class="productInfo_part2">
        <div class="container2">
          <div class="rightbox">
            <p class="t2">{{ productBody.title2 }}</p>
            <p class="t3">{{ productBody.text2 }}</p>
          </div>
          <div class="fletbox">
            <img :src="productBody.src2">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductIntroduction',
  components: {
  },
  data() {
    return {
      swiperOptions: {
        loop: true,
        // autoplay: true,
        navigation: false,
        speed: 5,
        effect: 'fade',
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      },
      smallScreen: false,
      productBody: {
        title: '',
        src: '',
        name: '',
        src1: '',
        src2: '',
        text1: '',
        text2: ''
      },
      index: '',
      list: [
        { imgUrl: require('../../../assets/product2.png') },
        { imgUrl: require('../../../assets/product3.png') },
        { imgUrl: require('../../../assets/product4.png') },
        { imgUrl: require('../../../assets/product5.png') },
        { imgUrl: require('../../../assets/product6.png') }
      ]
    }
  },
  mounted() {
    // 添加resize事件监听器
    window.addEventListener('resize', this.handleResize)
    // 如果浏览器宽度小于等于1220就是手机平板宽度
    this.handleResize()
  },
  created() {
    const info = this.$route.query
    this.productBody = { ...info }
    console.log('productBody', this.productBody)
    // this.index = this.$route.query.index
    // console.log('index', this.index)
  },
  methods: {
    // 监听浏览器宽度
    handleResize() {
      // 如果浏览器宽度小于等于1220就是手机平板宽度
      if (window.innerWidth <= 1023) {
        this.smallScreen = true
      } else {
        this.smallScreen = false
      }
    }
  }
}
</script>
<style lang="scss">
@media screen and (min-width: 1024px) {
  .product_img_class {
    width: 100%;
    height: 100%;
    transition: .5s;
  }
  .product_img_class:hover {
    transform: scale(1.1);
  }
  .img_box {
    width: 100%;
    height: 68vh;
    overflow: hidden;
  }
  .productInfo_position .el-carousel .el-carousel__arrow {
    top: calc(50% - 20px); /* 调整箭头垂直居中位置 */
    background-color: transparent;
    width: 40px; /* 设置箭头宽度 */
    height: 40px; /* 设置箭头高度 */
    font-size: 50px; /* 设置箭头图标的大小 */
  }
  .productInfo_position .el-icon-arrow-left {
    color: gray;
  }
  .productInfo_position .el-icon-arrow-right {
    color: gray;
  }
  .productInfo_position .el-carousel .el-carousel__arrow--left {
    left: 600px; /* 调整箭头左侧位置 */
  }
  .productInfo_position .el-carousel .el-carousel__arrow--right {
    right: 600px; /* 调整箭头右侧位置 */
  }
  .productInfo_text {
    text-align: center;
  }
  .productInfo_text .t1 {
    color: #7a7a7a;
    font-size: 20px;
    padding-top: 25px;
    line-height: 30px;
  }
  .productInfo_text .t2 {
    color: #7a7a7a;
    font-size: 20px;
    padding-top: 25px;
    line-height: 30px;
  }
  .productInfo_part1 {
    margin-top: 100px;
    padding: 45px 0;
    .t2 {
        color: #000;
        line-height: 42px;
        text-align: left;
        font-size: 40px;
        font-family: Source Han Sans CN;
        font-weight: bold;
    }
    .t3 {
      margin-top: 20px;
      text-align: justify;
      white-space: pre-line;
      font-weight: 400;
      font-size: 1.5rem;
    }
  }
  .productInfo_part1 .container1 {
    max-width: 1140px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
  }
  .productInfo_part1 .container1 .fletbox{
    float: left;
    position: relative;
    width: 52%;
    overflow: hidden;
  }
  .productInfo_part1 .container1 .fletbox img {
    border: 0;
    width: 100%;
    vertical-align: top;
    transition: all 700ms ease;
  }
  .productInfo_part1 .container1 .fletbox img:hover {
    transform: scale(1.1);
  }
  .productInfo_part1 .container1 .rightbox img {
    border: 0;
    max-width: 100%;
    vertical-align: top;
    transition: all 700ms ease;
  }
  .productInfo_part1 .container1 .rightbox img:hover {
    transform: scale(1.1);
  }
  .productInfo_part1 .container1 .rightbox{
    float: right;
    width: 45%;
    left: auto;
    right: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .productInfo_part2 {
    margin-top: 100px;
    padding: 45px 0;
    .container2 {
      max-width: 1140px;
      margin: 0 auto;
      position: relative;
      overflow: hidden;
      width: 100%;
      .fletbox{
        float: right;
        position: relative;
        width: 52%;
        overflow: hidden;
        img {
          border: 0;
          width: 100%;
          vertical-align: top;
          transition: all 700ms ease;
        }
        img:hover {
          transform: scale(1.1);
        }
      }
      .rightbox{
        width: 45%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        text-align: justify;
        .t2 {
            color: #000;
            line-height: 42px;
            text-align: left;
            font-size: 40px;
            font-family: Source Han Sans CN;
            font-weight: bold;
        }
        .t3 {
          margin-top: 20px;
          text-align: justify;
          white-space: pre-line;
          font-weight: 400;
          font-size: 1.5rem;
        }
      }
    }
  }
  .productBody1 {
    width: 1200px;
    overflow: hidden;
    margin: 0px auto;
    position: relative;
    .img_src1{
      width: 50%;
      margin: 0 auto;
      margin-top: 100px;
      overflow: hidden;
      img{
        width: 100%;
        transition: .5s;
      }
      img:hover {
        transform: scale(1.1);
      }
    }
    .productBody1_table {
      margin: 0 auto;
      margin-top: 100px;
      table {
        width: 100%;
        tr {
          td {
            font-weight: 400;
            font-size: 1.5rem;
            color: #1f1e1e;
            text-align: center;
            padding: 8px;
            border-bottom: 1px solid #292929;
          }
        }
      }
    }
    .productBody1_text {
      .productBody1_title1 {
        font-size: 40px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        text-align: left;
      }
      .productBody1_text1 {
        font-weight: 400;
        font-size: 1.5rem;
        color: #1f1e1e;
        text-align: left;
        white-space: pre-line;
      }
      .productBody1_title2 {
        font-size: 40px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        text-align: left;
      }
      .productBody1_text2 {
        font-weight: 400;
        font-size: 1.5rem;
        color: #1f1e1e;
        text-align: left;
        white-space: pre-line;
      }
    }
  }
}
@media screen and (max-width: 1023px) {
  .product_img_class {
    width: 100%;
    height: 100%;
    transition: .5s;
  }
  .product_img_class:hover {
    transform: scale(1.1);
  }
  .img_box {
    width: 100%;
    // height: 68vh;
    overflow: hidden;
  }
  .productInfo_position .el-carousel .el-carousel__arrow {
    top: calc(50% - 20px); /* 调整箭头垂直居中位置 */
    background-color: transparent;
    width: 40px; /* 设置箭头宽度 */
    height: 40px; /* 设置箭头高度 */
    font-size: 50px; /* 设置箭头图标的大小 */
  }
  .productInfo_position .el-icon-arrow-left {
    color: gray;
  }
  .productInfo_position .el-icon-arrow-right {
    color: gray;
  }
  .productInfo_position .el-carousel .el-carousel__arrow--left {
    left: 600px; /* 调整箭头左侧位置 */
  }
  .productInfo_position .el-carousel .el-carousel__arrow--right {
    right: 600px; /* 调整箭头右侧位置 */
  }
  .productInfo_position .pruduct_box .my-swipe1a {
    width: 100%;
    height: 200px;
  }
  .productInfo_position .pruduct_box .my-swipe1a .background-container1a {
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    background-position: center;
  }
  .productInfo_text {
    text-align: center;
  }
  .productInfo_text .t1 {
    color: #7a7a7a;
    font-size: 15px;
    padding-top: 25px;
    line-height: 30px;
  }
  .productInfo_text .t2 {
    color: #7a7a7a;
    font-size: 15px;
    padding-top: 25px;
    line-height: 30px;
  }
  .productInfo_part1 {
    // margin-top: 100px;
    padding: 20px 20px;
    .t2 {
        color: #000;
        line-height: 20px;
        text-align: left;
        font-size: 15px;
        font-family: Source Han Sans CN;
        font-weight: bold;
    }
    .t3 {
      // margin-top: 20px;
      text-align: justify;
      white-space: pre-line;
      font-weight: 400;
      font-size: 13px;
    }
  }
  .productInfo_part1 .container1 {
    // max-width: 1140px;
    // margin: 0 auto;
    // position: relative;
    // overflow: hidden;
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
  }
  .productInfo_part1 .container1 .fletbox{
    // float: left;
    /* position: relative; */
    width: 48%;
    /* overflow: hidden; */
  }
  .productInfo_part1 .container1 .fletbox img {
    border: 0;
    width: 100%;
    vertical-align: top;
    transition: all 700ms ease;
  }
  .productInfo_part1 .container1 .fletbox img:hover {
    transform: scale(1.1);
  }
  .productInfo_part1 .container1 .rightbox img {
    border: 0;
    max-width: 100%;
    vertical-align: top;
    transition: all 700ms ease;
  }
  .productInfo_part1 .container1 .rightbox img:hover {
    transform: scale(1.1);
  }
  .productInfo_part1 .container1 .rightbox{
    // float: right;
    width: 48%;
  }
  .productInfo_part2 {
    // margin-top: 100px;
    padding: 20px 20px;
    .container2 {
      // max-width: 1140px;
      // margin: 0 auto;
      // position: relative;
      // overflow: hidden;
      // width: 100%;
      overflow: hidden;
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-evenly;
      align-items: center;
      .fletbox{
        float: right;
        position: relative;
        width: 50%;
        overflow: hidden;
        // margin-top: 60px;
        img {
          border: 0;
          width: 100%;
          vertical-align: top;
          transition: all 700ms ease;
        }
        img:hover {
          transform: scale(1.1);
        }
      }
      .rightbox{
        width: 50%;
        // position: absolute;
        //top: 50%;
        // transform: translateY(-50%);
        // left: 0;
        // right: 0;
        text-align: justify;
        .t2 {
            color: #000;
            line-height: 20px;
            text-align: left;
            font-size: 15px;
            font-family: Source Han Sans CN;
            font-weight: bold;
        }
        .t3 {
          // margin-top: 20px;
          text-align: justify;
          white-space: pre-line;
          font-weight: 400;
          font-size: 13px;
        }
      }
    }
  }
  .productBody1 {
    // width: 1200px;
    overflow: hidden;
    margin: 0px auto;
    position: relative;
    .img_src1{
      width: 50%;
      margin: 0 auto;
      margin-top: 50px;
      overflow: hidden;
      img{
        width: 100%;
        transition: .5s;
      }
      img:hover {
        transform: scale(1.1);
      }
    }
    .productBody1_table {
      margin: 0 auto;
      margin-top: 50px;
      padding: 0px 10px;
      table {
        width: 100%;
        tr {
          td {
            font-weight: 400;
            font-size: 14px;
            color: #1f1e1e;
            text-align: center;
            padding: 4px;
            border-bottom: 1px solid #292929;
          }
        }
      }
    }
    .productBody1_text {
      padding: 0px 10px;
      .productBody1_title1 {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        text-align: center;
      }
      .productBody1_text1 {
        font-weight: 400;
        font-size: 14px;
        color: #1f1e1e;
        text-align: left;
        white-space: pre-line;
      }
      .productBody1_title2 {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        text-align: center;
      }
      .productBody1_text2 {
        font-weight: 400;
        font-size: 14px;
        color: #1f1e1e;
        text-align: left;
        white-space: pre-line;
      }
    }
  }
}
</style>

import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Vant from 'vant'
import 'vant/lib/index.css'
import '@vant/touch-emulator'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '@/icons'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
// import 'amfe-flexible'

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(Vant)
Vue.use(VueAwesomeSwiper)
router.beforeEach((to, from, next) => {
  // 在每次路由跳转前将滚动位置设置为顶部
  window.scrollTo(0, 0)
  next()
})
new Vue({
  router,
  store,
  el: '#app',
  render: (h) => h(App)
}).$mount('#app')

<template>
  <div class="product_div_class">
    <div class="product_title_class">
      <div class="product_title_container">
        <div class="h3"> 产品</div>
      </div>
    </div>
    <div class="product_contian">
      <div class="contain_default">
        <div style="border-bottom: none;margin-bottom: 0;">
          <!-- <div class="navbar">
            <ul>
              <li><a title="全部" @click="changeProduct('0')" :class="{'hovered': this.currentType === '0' }">全部</a></li>
              <li><a title="图像识别拍摄设备" @click="changeProduct('1')" :class="{'hovered': this.currentType === '1' }">图像识别设备</a></li>
              <li><a title="步行辅助设备" @click="changeProduct('2')" :class="{'hovered': this.currentType === '2' }">步行辅助设备</a></li>
              <li><a title="VR体感动作捕捉设备" @click="changeProduct('3')" :class="{'hovered': this.currentType === '3' }">动作捕捉设备</a></li>
              <li><a title="信号处理设备" @click="changeProduct('4')" :class="{'hovered': this.currentType === '4' }">信号处理设备</a></li>
            </ul>
          </div> -->
          <div class="product_list">
            <ul>
              <li v-for="(item, index) in productList" :key="index">
                <a :title="item.title">
                  <div class="box" @click="goProductIntroduction(item)">
                    <div class="pic">
                      <img :src="item.src" class="middleCenter">
                    </div>
                    <div class="text">
                      <div class="bt">{{ item.name }}</div>
                      <div><p></p></div>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div style="clear: both;"></div>
  </div>
</template>

<script>
export default {
  name: 'ProductIndex',
  components: {
  },
  watch: {
    '$route'(to, from) {
      // 获取目标路由的路径
      const toPath = to.path
      // 获取目标路由的参数
      const toTypeNumber = to.query.type
      // // 获取来源路由的路径
      // const fromPath = from.path
      // // 获取来源路由的参数
      // const fromTypeNumber = from.query.type
      if (toPath === '/Product') {
        if (toTypeNumber === '0') {
          this.productList = [...this.productList1, ...this.productList2, ...this.productList3, ...this.productList4]
        } else if (toTypeNumber === '1') {
          this.productList = this.productList1
        } else if (toTypeNumber === '2') {
          this.productList = this.productList2
        } else if (toTypeNumber === '3') {
          this.productList = this.productList3
        } else if (toTypeNumber === '4') {
          this.productList = this.productList4
        }
        this.currentType = toTypeNumber
      }
    }
  },
  data() {
    return {
      productList4: [
        {
          title: '信号处理',
          backBigImg: require('../../assets/bigImg4.png'),
          src: require('../../assets/信号处理1.png'),
          name: '信号处理设备',
          src1: require('../../assets/信号处理1.png'),
          src2: require('../../assets/信号处理2.png'),
          title1: '◆设备特点',
          text1: '·支持USB 2.0、便携、无需外部电源、8路继电器输出通道，\n8路隔离数字量输入、显示继电器状态的LED指示灯、8个C型继电器输出通道、输入通道高电压隔离(2，500VDC)。',
          title2: '◆设备参数',
          text2: '·60MHz/30MHz的频率输出，全频段1μHz的分辨率;\n·采用DDS实现方法，具有200MSa/s采样速度和14bits垂直分辨率;\n·低抖动方波输出;\n·兼容TTL电平信号的7位高精度频率计;\n·24组非易失数字任意波形存储;'
        }
      ],
      productList2: [
        {
          title: '医疗辅助设备',
          backBigImg: require('../../assets/bigImg2.png'),
          src: require('../../assets/步行辅助设备1.png'),
          name: '步行辅助设备',
          src1: require('../../assets/步行辅助设备2.png'),
          src2: '',
          title1: '◆通过支撑体重进行辅助的手法',
          text1: '·依靠电机的力量来带动鞋子和座椅间的框架像人体膝盖一样进行弯曲和伸展，同时抬起座椅来支撑人体体重，减轻腿部肌肉和关节（股关节、膝关节、踝关节）的负担。\n·独自开发出能够跟随人体和腿部行动的座椅和框架结构，辅助力能够配合使用者的腿部力量和身体重心，能够为使用者在步行、上下台阶、弯腰等各种动作和姿势下提供助力。',
          title2: '◆自然的辅助力控制',
          text2: '·通过安装在鞋子内的感应器获取相应信息，并据此控制两个电机，区分双腿动作，分配出左右腿行动所需的辅助力，实现自然的行走。\n·配合膝盖的弯曲调整辅助力，提高上下台阶和弯腰等膝盖承受负担较大的动作和姿势时的效果。'
        }
      ],
      productList1: [
        {
          title: '智慧海洋',
          backBigImg: require('../../assets/bigImg1.png'),
          src: require('../../assets/图像识别拍摄设备1.png'),
          name: '图像识别拍摄设备',
          src1: require('../../assets/图像识别拍摄设备2.png'),
          src2: require('../../assets/图像识别拍摄设备3.png'),
          src3: require('../../assets/图像识别拍摄设备4.png'),
          src4: require('../../assets/图像识别拍摄设备5.png'),
          src5: require('../../assets/图像识别拍摄设备6.png'),
          title1: '◆高速摄像仪',
          text1: '·分辨率：640×512像素;\n·特征等效温差NETD：18mk;\n·超宽温度档：-15℃-2500℃;\n·测温精度：±1k or ±1%;\n·帧频：200Hz@640×512;',
          title2: '◆高性能高速相机',
          text2: '·分辨率≥1920×1080;\n·帧速率（fps）≥1000（最高分辨率下、10-bit）;\n·曝光时间（ms）≥0.001',
          title3: '◆高性能红外相机',
          text3: '·分辨率有500万;\n·传感器水平方向上的像素数6K;\n·像素深度32位;·动态范围广、曝光不失真、全彩模式，显示高品质画面;\n·可实现黑白/彩色，镜像/倒像，翻转，自动白平衡，自动增益等功能;\n·防风、防水、防海雾。;',
          title4: '◆强大的片上图像处理能力',
          text4: '·内嵌高性能FPGA及2片512MB DDR3芯片，双核ARM A9，16MB QSPI，4GB片上存储，可在拍照过程中对传感器捕获的数据进行实时同步解算。',
          title5: '◆超大FOV',
          text5: '·超大FOV，89°*89°的视场角为追踪范围内无死角捕捉提供了保障。'
        }
      ],
      productList3: [
        {
          title: '虚拟现实',
          backBigImg: require('../../assets/bigImg3.png'),
          src: require('../../assets/头戴设备2.png'),
          name: 'VR体感动作捕捉设备',
          src1: require('../../assets/头戴设备1.png'),
          src2: require('../../assets/头戴设备2.png'),
          src3: require('../../assets/头戴设备3.png'),
          src4: require('../../assets/头戴设备4.png'),
          title1: '◆基本参数',
          text1: '·3840 x 1920的双眼分辨率;\n·可拆卸，增强设备的便携性;\n·实现长达2小时的连续使用;\n·支持热插拔和电池更换;\n·可调节，实现头部的精确贴合;\n·解锁PC VR的强大功能;\n·符合人体工程学设计。',
          title2: '◆动作捕捉设备参数',
          text2: '·惯性传感器:7组九轴惯性传感器;\n·动态精度:Rol0.5°，Pitch0.5°，YaW2°;\n·加速计量程:±16g;\n·陀螺仪量程:±2000dps;\n·数据传输帧率:10-500Hz;',
          title3: '◆仿真体感',
          text3: '·在VR中查看和使用你的双手，以获得更身临其境的元宇宙体验。;\n·确保你在虚拟环境中交互的精准度达到新水准。;\n·内置手势识别功能，可解锁你在VR应用程序中的自定义操作。;',
          title4: '◆体感控制器',
          text4: '·手势识别代替鼠标;\n·可视化任务的3D手势控制器'
        }
      ],
      productList: [],
      // 当前tab
      currentType: '0'
    }
  },
  created() {
    this.productList = [...this.productList1, ...this.productList2, ...this.productList3]
  },
  mounted() {
    const type = this.$route.query.type
    if (type === '0') {
      this.productList = [...this.productList1, ...this.productList2, ...this.productList3, ...this.productList4]
    } else if (type === '1') {
      this.productList = this.productList1
    } else if (type === '2') {
      this.productList = this.productList2
    } else if (type === '3') {
      this.productList = this.productList3
    } else if (type === '4') {
      this.productList = this.productList4
    }
    this.currentType = type
  },
  methods: {
    // 跳转设备说明
    goProductIntroduction(item) {
      this.$router.push({ path: '/ProductIntroduction', query: item }).catch(err => console.log(err))
      this.$store.commit('setGlobalVar', '333')
    },
    changeProduct(type) {
      if (type === '0') {
        this.productList = [...this.productList1, ...this.productList2, ...this.productList3, ...this.productList4]
      } else if (type === '1') {
        this.productList = this.productList1
      } else if (type === '2') {
        this.productList = this.productList2
      } else if (type === '3') {
        this.productList = this.productList3
      } else if (type === '4') {
        this.productList = this.productList4
      }
      this.currentType = type
    }
  }
}
</script>
<style lang="scss">
@media screen and (min-width: 1024px) {
  .product_div_class {
    background-color: white;
  }
  .product_title_class {
    padding: 100px 0 50px 0;
    background-color: white;
    clear: both;
  }
  .product_box-card {
    display: flex;
    justify-content: center; /* 水平居中 */
    margin-top: 50px;
    margin-left: 20px;
    margin-right: 50px;
    padding: 10px;
    width: 500px;
    height: 500px;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
  }
  .product_img_class {
    width: 100%;
    height: 100%;
  }
  .product_picture {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .product_box-card .el-card__body {
    width: 400px;
    height: 400px;
  }
  .product_contian {
    background-color: white;
    padding-top: 34px;
    clear: both;
  }
  .contain_default {
    width: 1320px;
    margin-left: auto;
    margin-right: auto;
  }
  .product-navbar ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .product-navbar li {
    margin: 0 15px;
  }
  .product-navbar p {
    text-decoration: none;
    font-size: 30px;
    font-weight: 300;
    line-height: 40px;
    color: #2c3e50;
    padding-left: 17px;
    border-left: 2px solid transparent;
  }
  .product_title_container{
      width: 1320px;
      margin-left: auto;
      margin-right: auto;
  }
  .h3 {
    color: black;
    font-size: 34px;
    text-align: left;
  }
  .navbar {
    float: left;
    background: #fff;
    min-height: 400px;
    width: 20%;
    padding-top: 30px;
  }
  .navbar ul li:hover a {
    cursor: pointer;
    border-left: 2px solid #44a8fa;
  }
  .navbar ul li .hovered {
    border-left: 2px solid #44a8fa;
  }
  .navbar ul li {
    margin-bottom: 20px;
    zoom: 1;
    vertical-align: middle;
  }
  .navbar ul li a {
    text-align:left;
    font-size: 16px;
    color: #363636;
    display: block;
    line-height: 25px;
    border-left: 2px solid transparent;
    padding-left: 30px;
    position: relative;
    margin-bottom: 10px;
  }
  .product_list {
    // float: right;
    // width: 78.5%;
    padding-bottom: 50px;
  }
  .product_list ul{
    margin-top: -10px;
    margin-bottom: 50px;
    overflow: hidden;
    padding: 0;
  }
  .product_list ul li{
    width: 100%;
    float: left;
    text-align: left;
    zoom: 1;
    vertical-align: middle;
    list-style: none;
  }
  .product_list ul li a .box {
    display: block;
    background: #fff;
    margin: 10px;
    transition: box-shadow 0.3s ease-in-out; /* 添加过渡效果 */
  }
  .product_list ul li a .box:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* 添加阴影 */
    cursor: pointer;
  }
  .product_list ul li a .box .pic {
      width: 100%;
      height: 700px;
      overflow: hidden;
      position: relative;
  }
  .product_list ul li a .box .text {
    padding: 20px;
  }
  .product_list ul li a .box .text .bt {
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    height: 26px;
    line-height: 26px;
    overflow: hidden;
  }
  .middleCenter {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      max-width: 100%;
      max-height: 100%;
      display: block;
      vertical-align: middle;
      text-align: center;
      margin: auto;
  }
  .product_list img {
    transition: transform 0.3s ease;
  }
  .product_list img:hover {
    transform: scale(1.1);
  }
}
@media screen and (max-width: 1023px) {
  .product_title_class{
    padding: 70px 0 25px 25px;
    background: #f7f8f9;
    clear: both;
  }
  .product_box-card {
    display: flex;
    justify-content: center; /* 水平居中 */
    margin-top: 50px;
    margin-left: 20px;
    margin-right: 50px;
    padding: 10px;
    width: 500px;
    height: 500px;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
  }
  .product_img_class {
    width: 100%;
    height: 100%;
  }
  .product_picture {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .product_box-card .el-card__body {
    width: 400px;
    height: 400px;
  }
  .product_contian {
    background: #f7f8f9;
    // padding-top: 34px;
    clear: both;
  }
  .contain_default {
    // width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
  .product-navbar ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .product-navbar li {
    margin: 0 15px;
  }
  .product-navbar p {
    text-decoration: none;
    font-size: 30px;
    font-weight: 300;
    line-height: 40px;
    color: #2c3e50;
    padding-left: 17px;
    border-left: 2px solid transparent;
  }
  .product_title_container{
      // width: 1200px;
      margin-left: auto;
      margin-right: auto;
  }
  .h3 {
    color: black;
    font-size: 34px;
    text-align: left;
  }
  .navbar {
    // float: left;
    background: #fff;
    // min-height: 400px;
    width: 100%;
    // padding-top: 30px;
  }
  .navbar ul {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    align-items: center;
  }
  // .navbar ul li:hover {
  //   cursor: pointer;
  //   // padding: 20px;
  //   border-bottom: 2px solid #44a8fa;
  // }
  .navbar ul li .hovered {
    border-bottom: 2px solid black;
  }
  .navbar ul li {
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 100px;
    display: flex;
    align-items: center;
  }
  .navbar ul li a {
    font-size: 16px;
    color: #363636;
    text-align: center;
    width: 100%;
    text-decoration: none;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .product_list {
    // float: right;
    // width: 78.5%;
    padding-bottom: 50px;
  }
  .product_list ul{
    // margin-top: -10px;
    // margin-bottom: 50px;
    overflow: hidden;
    padding: 0;
  }
  .product_list ul li{
    // width: 50%;
    // float: left;
    // text-align: left;
    // zoom: 1;
    vertical-align: middle;
    list-style: none;
  }
  .product_list ul li a .box {
    display: block;
    background: #fff;
    margin: 10px;
    transition: box-shadow 0.3s ease-in-out; /* 添加过渡效果 */
  }
  // .product_list ul li a .box:hover {
  //   box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* 添加阴影 */
  //   cursor: pointer;
  // }
  .product_list ul li a .box .pic {
      width: 100%;
      height: 350px;
      overflow: hidden;
      position: relative;
  }
  .product_list ul li a .box .text {
    padding: 20px;
  }
  .product_list ul li a .box .text .bt {
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    height: 26px;
    line-height: 26px;
    overflow: hidden;
  }
  .middleCenter {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      max-width: 100%;
      max-height: 100%;
      display: block;
      vertical-align: middle;
      text-align: center;
      margin: auto;
  }
  .product_list img {
    transition: transform 0.3s ease;
  }
  // .product_list img:hover {
  //   transform: scale(1.1);
  // }
}
</style>

<template>
  <div class="caseDetail_container">
    <div class="bread_tab_container">
      <div class="bread_tab">
        <p>案例分享 - {{ bodyData.name }}</p>
        <!-- <div v-if="!headerStyle" class="go_back_button" @click="toback()">
          <img src="../../../assets/goback.png"/>
          <p style="display: inline-block; cursor: pointer;font-size: 20px">返回上级</p>
        </div> -->
        <a v-if="!headerStyle" class="go_back_button" @click.prevent="toback()"><img src="../../../assets/goback.png"/><span>返回上级</span></a>
      </div>
    </div>
    <div class="detail_container">
      <div class="detail_con">
        <div class="container">
          <div class="h1">{{ bodyData.name }}</div>
          <div v-if="areaName === '应用级客户定制开发'">
            <img src="../../../assets/statement.png" class="statementImg">
          </div>
          <div v-if="bodyData.srcinfo1 !== ''">
            <p>
              <img :src="bodyData.srcinfo1">
            </p>
            <div class="case_text">
              <p>{{ bodyData.text1 }}</p>
            </div>
          </div>
          <div v-if="bodyData.srcinfo2 !== ''">
            <p style="margin-top:100px">
              <img :src="bodyData.srcinfo2">
            </p>
            <div class="case_text">
              <p>{{ bodyData.text2 }}</p>
            </div>
          </div>
          <div v-if="bodyData.srcinfo3 !== ''">
            <p style="margin-top:100px">
              <img :src="bodyData.srcinfo3">
            </p>
            <div class="case_text">
              <p>{{ bodyData.text3 }}</p>
            </div>
          </div>
          <div v-if="bodyData.srcinfo4 !== ''">
            <p style="margin-top:100px">
              <img :src="bodyData.srcinfo4">
            </p>
            <div class="case_text">
              <p>{{ bodyData.text4 }}</p>
            </div>
          </div>
          <div v-if="bodyData.srcinfo5 !== ''">
            <p style="margin-top:100px">
              <img :src="bodyData.srcinfo5">
            </p>
            <div class="case_text">
              <p>{{ bodyData.text5 }}</p>
            </div>
          </div>
          <div v-if="bodyData.srcinfo6 !== ''">
            <p style="margin-top:100px">
              <img :src="bodyData.srcinfo6">
            </p>
            <div class="case_text">
              <p>{{ bodyData.text6 }}</p>
            </div>
          </div>
          <div v-if="bodyData.srcinfo7 !== ''">
            <p style="margin-top:100px">
              <img :src="bodyData.srcinfo7">
            </p>
            <div class="case_text">
              <p>{{ bodyData.text7 }}</p>
            </div>
          </div>
          <div v-if="bodyData.name === '图形图像识别算法'"  class="table">
            <h2>高性能高速相机</h2>
            <table>
              <thead>
                <tr>
                  <th>分辨率</th>
                  <th>帧速率(fps)</th>
                  <th>曝光时间(ms)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ '≥1920*1080' }}</td>
                  <td>{{ '≥1000(最高分辨率下、10-bit)' }}</td>
                  <td>{{ '≥0.001' }}</td>
                </tr>
              </tbody>
            </table>
            <h2>高速摄像仪</h2>
            <table>
              <thead>
                <tr>
                  <th>分辨率</th>
                  <th>特征等效温差NETD</th>
                  <th>超宽温度档</th>
                  <th>测温精度</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ '640*512像素' }}</td>
                  <td>{{ '18mk' }}</td>
                  <td>{{ '-15℃-2500℃' }}</td>
                  <td>{{ '±1k or ±1%' }}</td>
                </tr>
              </tbody>
            </table>
            <h2>高性能云台</h2>
            <table>
              <thead>
                <tr>
                  <th>旋转速度</th>
                  <th>旋转角度</th>
                  <th>承重</th>
                  <th>定位角度分辨率</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ '水平0.1°~60°/s 俯仰0.1°~30°/s' }}</td>
                  <td>{{ '水平0~360°连续旋转 俯仰+50°~-50°' }}</td>
                  <td>{{ '≥25kg' }}</td>
                  <td>{{ '0.01°' }}</td>
                </tr>
              </tbody>
            </table>
            <h2>便携式移动终端</h2>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>CPU</th>
                  <th>内存</th>
                  <th>硬盘空间</th>
                  <th>显卡</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ '最低配置要求' }}</td>
                  <td>{{ 'Intel i5' }}</td>
                  <td>{{ '16GB' }}</td>
                  <td>{{ '1TB' }}</td>
                  <td>{{ '集成显卡' }}</td>
                </tr>
                <tr>
                  <td>{{ '推荐配置' }}</td>
                  <td>{{ 'Intel i7' }}</td>
                  <td>{{ '32GB' }}</td>
                  <td>{{ '2TB' }}</td>
                  <td>{{ 'GTX 1080' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!headerStyle" class="case_goback">
      <a class="case_goback_icon" @click.prevent="goTop()"><img src="../../../assets/goTop.png"/><span>回到顶部</span></a>
    </div>
    <div v-else class="float">
      <ul>
        <li>
          <a @click.prevent="toback()">
            <img src="../../../assets/goback.png"/>
            <span>返回上级</span>
          </a>
        </li>
        <li>
          <a @click.prevent="goTop()">
            <img src="../../../assets/goTop.png"/>
            <span>回到顶部</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ClassicCaseDetail',
  data() {
    return {
      areaName: '',
      headerStyle: true,
      ID: '',
      bodyData: {
        name: '',
        src1: '',
        src2: '',
        srcinfo1: '',
        srcinfo2: '',
        srcinfo3: '',
        srcinfo4: '',
        srcinfo5: '',
        srcinfo6: '',
        srcinfo7: '',
        text1: '',
        text2: '',
        text3: '',
        text4: '',
        text5: '',
        text6: '',
        text7: ''
      }
    }
  },
  methods: {
    toback() {
      this.$router.push({ path: '/ClassicCase', query: { type: this.ID }}).catch(err => console.log(err))
    },
    goTop() {
      window.scrollTo({
        top: 0, // 指定顶部的位置，0表示最顶部
        behavior: 'smooth' // 指定滚动行为为平滑滚动
      })
    }
  },
  created() {
    const info = this.$route.query.caseOne
    const title = this.$route.query.areaName
    const id = this.$route.query.id
    this.bodyData = { ...info }
    this.ID = id
    this.areaName = title
  },
  mounted() {
    // 如果浏览器宽度小于等于1220
    if (window.innerWidth <= 1220) {
      this.headerStyle = false
    } else {
      this.headerStyle = true
    }
  }
}
</script>
<style lang="scss">
@media screen and (min-width: 1024px) {
  .caseDetail_container {
  padding-top: 100px;
  padding-bottom: 100px;
  background: #171717;
    .bread_tab_container {
      max-width: 1320px;
      width: 100%;
      margin: 0 auto;
      .bread_tab {
        padding: 0 0 40px 0;
        font-size: 24px;
        border-bottom: solid 1px #444;
        text-align: left;
        color: #fff;
      }
    }
    .detail_container {
      color: #fff;
      padding-bottom: 40px;
      line-height: 32px;
      font-size: 16px;
      .detail_con {
        padding-top: 70px;
        text-align: center;
        .container {
          max-width: 1320px;
          width: 100%;
          margin: 0 auto;
          .h1 {
            font-size: 60px;
            line-height: 70px;
            p {
              margin-top:200px;
            }
          }
          .statementImg {
            border: 0;
            max-width: 100%;
            vertical-align: top;
            // width: 100%;
            // height: 100%;
          }
          p {
            img {
              border: 0;
              max-width: 100%;
              vertical-align: top;
              width: 80%;
              height: 80%;
            }
          }
          .case_text {
            margin: 0 auto;
            margin-top: 20px;
            white-space-collapse: preserve;
            text-align: left;
            font-size: 20px;
            width: 80%;
            text-indent: 2em;
          }
          .table {
            margin: 20px;
            table {
              border-collapse: collapse;
              width: 100%;
              th {
                background-color: #f2f2f2;
                color: #444;
                td {
                  text-align: center;
                  padding: 8px;
                  border-bottom: 1px solid #ddd;
                }
              }
              tr:hover {
                background-color: #f3f5ef;
                color: #444;
                td {
                  text-align: center;
                  padding: 8px;
                  border-bottom: 1px solid #444;
                }
              }
              tr {
                td {
                  text-align: center;
                  padding: 8px;
                  border-bottom: 1px solid #ddd;
                }
              }
            }
            h2 {
              margin-top: 40px;
            }
          }
        }
      }
    }
    .case_goback {
      position: absolute;
      display: inline-block;
      text-align: center;
      right: calc(20%);
      .case_goback_icon {
        color: #fff;
        // width: 40px;
        // height: 40px;
        display: inline-block;
        // border-radius: 40px;
        // background: #007bc2;
        font-size: 24px;
        // margin-top: 7vh;
      }
      .case_goback_icon:hover {
        cursor: pointer;
      }
    }
    .float {
      position: fixed;
      // top: 50vh;
      bottom: 0vh;
      right: 10vw;
      li {
        padding: 20px 0px;
        a {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center; /* 让文本居中对齐 */
          text-decoration: none; /* 移除<a>标签的下划线 */
          color: #fff;
          img {
            width: 30px
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1023px) {
  .caseDetail_container {
  padding-top: 50px;
  padding-bottom: 120px;
  background: #171717;
    .bread_tab_container {
      max-width: 1320px;
      width: 100%;
      margin: 0 auto;
      .bread_tab {
        padding: 0px 35px;
        font-size: 12px;
        border-bottom: solid 1px #444;
        text-align: left;
        color: #fff;
        .go_back_button {
          display: flex;
          text-align: center;
          font-size: 20px;
          align-items: center;
          justify-content: flex-end;
          img {
            width: 20px
          }
        }
      }
    }
    .detail_container {
      color: #fff;
      padding-bottom: 40px;
      line-height: 32px;
      font-size: 16px;
      .detail_con {
        padding-top: 20px;
        text-align: center;
        .container {
          max-width: 1320px;
          width: 100%;
          margin: 0 auto;
          .h1 {
            font-size: 20px;
            line-height: 30px;
            p {
              margin-top:200px;
            }
          }
          .statementImg {
            border: 0;
            width: 50%;
            vertical-align: top;
            // width: 100%;
            // height: 100%;
          }
          p {
            img {
              border: 0;
              vertical-align: top;
              width: 94%;
            }
          }
          .case_text {
            margin-top: 0 auto;
            white-space-collapse: preserve;
            text-align: center;
            font-size: 20px;
            padding: 0 4%;
            p {
              text-align: left;
              text-indent: 2em;
            }
          }
          .table {
            margin: 20px;
            font-size: 12px;
            table {
              border-collapse: collapse;
              width: 100%;
              th {
                background-color: #f2f2f2;
                color: #444;
                td {
                  text-align: center;
                  padding: 8px;
                  border-bottom: 1px solid #ddd;
                }
              }
              tr:hover {
                background-color: #f3f5ef;
                color: #444;
                td {
                  text-align: center;
                  padding: 8px;
                  border-bottom: 1px solid #444;
                }
              }
              tr {
                td {
                  text-align: center;
                  padding: 8px;
                  border-bottom: 1px solid #ddd;
                }
              }
            }
            h2 {
              margin-top: 40px;
            }
          }
        }
      }
    }
    .case_goback {
      position: absolute;
      display: inline-block;
      text-align: center;
      // right: calc(20%);
      .case_goback_icon {
        color: #fff;
        // width: 40px;
        // height: 40px;
        display: flex;
        // border-radius: 40px;
        // background: #007bc2;
        font-size: 24px;
        // margin-top: 7vh;
        text-align: center;
        justify-content: center;
        img {
          width: 30px;
        }
      }
      .case_goback_icon:hover {
        cursor: pointer;
      }
    }
  }
}
</style>

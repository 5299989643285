<template>
  <div class="case_container1">
    <div class="container2">
      <!-- <diV class="title">案例分享</diV> -->
      <div v-if="headerStyle" class="backButton">
        <div class="button" @click="goTop()">
          <img src="../../assets/goTop.png"/>
          <span>{{ '回到顶部' }}</span>
        </div>
      </div>
      <div class="area_Navigation_bar">
        <ul class="area_ul">
          <li v-for="(areaItem, index) in ClassicCase" :key="index" @click="goToArea(index)">
            <div class="area_Navigation_title"><p>{{ areaItem.caseAreaName }}</p></div>
          </li>
        </ul>
      </div>
      <div class="area_out">
        <ul>
          <li v-for="(item, index) in ClassicCase" :key="index" :id="'areaPart' + (index+1)">
            <div class="areaTitle" :style="{backgroundImage: 'url('+ item.imageUrl +')' }">
              {{ item.caseAreaName }}
            </div>
            <div class="areaContainer">
              <div v-for="(caseOne, caseIndex) in item.caseAreaList" :key="caseIndex" class="caseItem" :id="'areaPart' + (index+1) + (caseIndex+1)">
                <div class="caseContainer" @click="goToInfo(caseOne, item.caseAreaName, index, caseIndex)">
                  <div>
                    <img class="img_show" :src="caseOne.src1"/>
                    <img class="img_shadow" :src="caseOne.src1"/>
                  </div>
                  <div class="card_text">
                    <div class="caseTitle"><img src="../../assets/titleicon.png"><span>{{ '案例'+ (caseIndex+1) + '---' + caseOne.name }}</span></div>
                    <p>{{ caseOne.text }}</p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div v-if="!headerStyle" class="goTopButton" @click="goTop()"><img src="../../assets/goTop.png" style="width: 20px"/><span>{{ '回到顶部' }}</span></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClassicCase',
  components: {
  },
  watch: {
    '$route'(to, from) {
      // 获取目标路由的路径
      const toPath = to.path
      // 获取目标路由的参数
      const toTypeNumber = to.query.type
      // // 获取来源路由的路径
      // const fromPath = from.path
      // // 获取来源路由的参数
      // const fromTypeNumber = from.query.type
      console.log('toPath', toPath)
      if (toPath === '/ClassicCase') {
        const targetElement = document.querySelector('#areaPart' + toTypeNumber)
        console.log('targetElement', targetElement)
        if (targetElement) {
          window.scrollTo({
            top: targetElement.offsetTop - 150,
            behavior: 'smooth'
          })
        }
      }
    }
  },
  data() {
    return {
      infoFlag: false,
      areaName: '',
      headerStyle: true,
      backgroundColors: ['#F3D7B6', '#F8DFE0', '#CCEAF4', '#E5F1FB', '#D0EBE5', '#F8E6DF'],
      // areaList: [shiyan
      //   {
      //     titleName: '虚拟现实',
      //     subTitle: '虚拟仿真 、 辅助医疗',
      //     pic: require('../../assets/area2.png')
      //   },
      //   {
      //     titleName: '智慧农业',
      //     subTitle: '气候环境、 土壤监控',
      //     pic: require('../../assets/area1.png')
      //   },
      //   {
      //     titleName: '水下声学',
      //     subTitle: '海洋三维态势、 水下对抗训练',
      //     pic: require('../../assets/area3.png')
      //   },
      //   {
      //     titleName: 'AI大数据',
      //     subTitle: '神经网络',
      //     pic: require('../../assets/area4.png')
      //   }
      // ],
      caseArea1: {
        caseAreaName: '虚拟现实',
        imageUrl: require('../../assets/titlebackground1.png'),
        caseAreaList: [
          {
            name: '海洋三维态势实时监控平台',
            src1: require('../../assets/case_3d3.png'),
            srcinfo1: require('../../assets/case_3d1.png'),
            srcinfo2: require('../../assets/case_3d2.png'),
            srcinfo3: require('../../assets/case_3d3.png'),
            srcinfo4: require('../../assets/case_3d4.png'),
            srcinfo5: require('../../assets/case_3d6.png'),
            srcinfo6: require('../../assets/case_3d5.png'),
            text: '海上三维态势监控系统，结合了最新的物联网技术，收集海上传感器的数据，以3D全景展示模式来展现海上态势，助力智慧海洋工程发展。可应用于海上三维仿真试验，数字孪生，AIS实时对接等业务方向。',
            text1: '海上三维态势监控系统，结合了最新的物联网技术，收集海上传感器的数据，以3D全景展示模式来展现海上态势，助力智慧海洋工程发展。可应用于海上三维仿真试验，数字孪生，AIS实时对接等业务方向。',
            text2: '系统支持多种海洋船舶、设备高精度模型，包括测试船、陪试船、渔船、货船、浮标、水下设备等；系统同时支持海洋环境在不同时间、天气、海况条件下的模拟。',
            text3: '海洋数据三维可视化平台建立在海、陆、空多维立体信息采集系统基础上，实现了海洋环境类、自然地理类、海洋管理类等各种信息的可视化表达、综合查询、空间叠加分析，为海洋的数字化、可视化、透明化表达提供了崭新的海洋信息立体展现形式，为深入认识海洋、开发海洋、管理海洋开辟了新的途径和手段。',
            text4: '海洋监测技术一般可分为利用卫星、固定翼飞机、无人机等开展的天基海洋观测。利用岸基平台、热成像云台、雷达、传输线缆等组成的岸基海洋观测。利用无人监测船、调查科考船、浮标等组成的海基观测和水下传感装备、水下摄录装备等组成的水下海洋观测。海洋观测按照通讯距离可分为近浅海观测和深远海观测，观测数据主要在港口、海洋牧场、岸基环境、海洋气象、海洋水文、地形地貌、底质沉积物分析、生物分析、污染探测、经济数据分析等。',
            text5: '通过收集海上 船舶AIS、无人机GPS、浮标等数据，平台以三维全景展示模式来展现实时海上态势，软硬交互智能联动，助力智慧海洋工程。',
            text6: '多视角切换功能，用户可以根据需要自由切换不同视角，实时监控海上试验的情况。无论是水下设备、还是船舶、航空器，用户都可以在软件中随意切换视角，全方位掌握海上试验的动态变化，实现对海洋态势的全方位监控。另外，软件支持多维度展示海上试验效果，用户可以通过软件实时查看海水温度、盐度、流速等多种海洋参数的实时变化情况，帮助用户更好地了解海洋环境的变化和试验效果。同时，软件还支持对海上试验数据进行统计分析和报表展示，为用户提供全面的数据支持和决策参考。'
          },
          {
            name: '助船宝',
            src1: require('../../assets/船舶vr1.png'),
            srcinfo1: require('../../assets/船舶vr2.png'),
            srcinfo2: require('../../assets/船舶vr3.png'),
            srcinfo3: require('../../assets/船舶vr4.png'),
            srcinfo4: require('../../assets/船舶vr5.png'),
            srcinfo5: '',
            srcinfo6: '',
            text: '助船宝利用VR和3D仿真技术，对科考船、商务船等进行全方位立体展示，解决身在异地不能莅临现场的用户的问题。船舶介绍、设备介绍、联系方式等功能齐全，导航地图、图片导览等浏览模式可以快速定位到想了解的位置，交互友好。',
            text1: '助船宝利用VR和3D仿真技术，对科考船、商务船等进行全方位立体展示，解决身在异地不能莅临现场的用户的问题。船舶介绍、设备介绍、联系方式等功能齐全，导航地图、图片导览等浏览模式可以快速定位到想了解的位置，交互友好。',
            text2: '助船宝VR平台利用先进的虚拟现实技术，通过绘制的三维模型和全景展示，将用户带入其中国。用户可以在虚拟环境中360度自由观察船舶的外部结构和内部设施，深入了解船舶的各项功能和性能指标。',
            text3: '用户可以通过VR全景图更真实地感受科研双体船的内外部环境，包括船舱布局、设备配置等，有助于用户更准确地了解船舶情况。',
            text4: '虚拟3D模型。虚拟和现实相结合，双重体验。',
            text5: ''
          }
        ]
      },
      caseArea2: {
        caseAreaName: '海洋试验',
        imageUrl: require('../../assets/titlebackground2.png'),
        caseAreaList: [
          {
            name: '水上竞优仿真对抗系统',
            src1: require('../../assets/仿真竞优1.png'),
            srcinfo1: require('../../assets/仿真竞优2.png'),
            srcinfo2: require('../../assets/仿真竞优3.png'),
            srcinfo3: require('../../assets/仿真竞优4.png'),
            srcinfo4: require('../../assets/仿真竞优1.png'),
            srcinfo5: '',
            srcinfo6: '',
            text: '海洋水面上二维仿真竞优对抗试验系统，该系统以电子海图为基础，集成了水上船只、浮标、环境、声学等多维数据，针对红蓝双方，仿真推演、效果评估、水上竞优等提供技术支撑。',
            text1: '海洋水面上二维仿真竞优对抗试验系统，该系统以电子海图为基础，集成了水上船只、浮标、环境、声学等多维数据，针对红蓝双方，仿真推演、效果评估、水上竞优等提供技术支撑。',
            text2: '软件支持多维度的战术对抗模拟，用户可以根据实际需求设置不同的战术策略、风险评估和决策方案，进行多轮仿真对抗，以验证战术的合理性和有效性。同时，软件还支持对战术数据进行实时分析和回放，帮助用户深入了解战术对抗的过程和结果，为实际比赛和训练提供重要参考。',
            text3: '此外，我们的软件注重用户体验和操作便捷性，界面设计简洁直观，操作简单易懂，适用于各类用户群体。无论是水上运动爱好者、赛事裁判还是战术分析师，都可以轻松上手并发挥软件的强大功能，实现水上竞优仿真对抗的全面体验和应用。',
            text4: '集成了水上船只、浮标、环境、声学等多维数据，针对红蓝双方，仿真推演、效果评估、水上竞优演练试验等提供技术支撑。',
            text5: ''
          },
          {
            name: '水下航行器演练复盘系统',
            src1: require('../../assets/水下0.png'),
            srcinfo1: require('../../assets/水下1.png'),
            srcinfo2: require('../../assets/水下2.png'),
            srcinfo3: require('../../assets/水下3.png'),
            srcinfo4: require('../../assets/水下4.png'),
            srcinfo5: require('../../assets/水下5.png'),
            srcinfo6: require('../../assets/水下6.png'),
            srcinfo7: require('../../assets/水下7.png'),
            text: '水下航行器多维演练复盘系统，主要应用于水下机器人系统作业或者对抗演练等科研领域。根据对抗或协同多方的运动轨迹，以作业的关键事件为索引，将演练过程进行复盘，结合点、线、圆、文本等标注信息，以及任意时刻的相对态势，最终给出评估分析报告。为产品设计和演练方案的改进做数据支撑。',
            text1: '将复盘报告所需的轨迹、航行器材、佐证视频等数据，导入复盘系统后，该系统会根据相应算法，对数据自动纠错，平滑轨迹数据。',
            text2: '圆、文本等标注的样式可以编辑（位置、线形、颜色等）。',
            text3: '对抗作业时，彼此之间的相对态势支持随时查看（2者之间距离、角度等）。',
            text4: '对抗或协同作业各个关键考核事件。',
            text5: '关键事件节点可编辑、设定各自对应佐证视频，真是还原当时场景。',
            text6: '试验水域的水文环境、声速剖面图等环境信息展示&演算。',
            text7: '根据分析报告的侧重点，整个复盘要素均可编辑。'
          }
        ]
      },
      caseArea3: {
        caseAreaName: '先端算法',
        imageUrl: require('../../assets/titlebackground3.png'),
        caseAreaList: [
          {
            name: '轨迹曲线拟合算法',
            src1: require('../../assets/轨迹曲线拟合算法1.png'),
            srcinfo1: require('../../assets/轨迹曲线拟合算法1.png'),
            srcinfo2: require('../../assets/轨迹曲线拟合算法2.png'),
            srcinfo3: '',
            srcinfo4: '',
            srcinfo5: '',
            srcinfo6: '',
            text: '系统通过传感器接收目标发送的测试信号，来绘测目标的运行轨迹和速度。智能船舶系统采集的信息，通过本系统对水下航迹数据进行处理。基于不同时间、空间的多条航迹，以及航迹之间的关系，对接收到的数据进行修正，使得航迹结果接近真实。处理后的航行轨迹可用于演训复盘等应用。',
            text1: '系统通过传感器接收目标发送的测试信号，来绘测目标的运行轨迹和速度。智能船舶系统采集的信息，通过本系统对水下航迹数据进行处理。基于不同时间、空间的多条航迹，以及航迹之间的关系，对接收到的数据进行修正，使得航迹结果接近真实。处理后的航行轨迹可用于演训复盘等应用。',
            text2: '系统通过传感器接收目标发送的测试信号，来绘测目标的运行轨迹和速度。智能船舶系统采集的信息，通过本系统对水下航迹数据进行处理。基于不同时间、空间的多条航迹，以及航迹之间的关系，对接收到的数据进行修正，使得航迹结果接近真实。处理后的航行轨迹可用于演训复盘等应用。',
            text3: '',
            text4: '',
            text5: ''
          },
          {
            name: '图形图像识别算法',
            src1: require('../../assets/case_data5.png'),
            src2: require('../../assets/case_data2.png'),
            srcinfo1: require('../../assets/case_data5.png'),
            srcinfo2: require('../../assets/case_data4.png'),
            srcinfo3: require('../../assets/case_data3.png'),
            srcinfo4: require('../../assets/case_data1.png'),
            srcinfo5: require('../../assets/case_data6.png'),
            srcinfo6: '',
            text: '本系统为动态数据录取处理系统，系统主要用途为拍摄快速移动的目标某段时间的运动状态以及捕捉追踪目标的行进路线。',
            text1: '本系统为动态数据录取处理系统，系统主要用途为拍摄快速移动的目标某段时间的运动状态以及捕捉追踪目标的行进路线。',
            text2: '本系统的主要功能为远程控制云台调节相机的拍摄角度，将目标置于相机的视场范围中，通过相机采集到的实时画面调整相机的帧率、曝光时间、焦距等内部参数，使拍摄达到最佳，并将拍摄完成的视频文件存储到终端，通过视频回放页面进行查看。系统采用了先进的高清摄像头和红外传感器，能够实时捕捉海上飞行目标的图像信息，并通过高速数据处理技术，将图像传输到监控中心，实现对飞行目标的快速定位和跟踪。',
            text3: '系统具有高清晰度的图像采集能力，能够清晰记录海上飞行目标的各项细节，包括飞行器的型号、速度、航向等信息，为后续分析和识别提供了重要数据支持。同时，系统还采用红外技术，能够在复杂的光照条件下实现对目标的高效跟踪，保证监测的稳定性和准确性。',
            text4: '云台和相机设置页面：云台和相机设置页面分为高速相机云台设置和跟踪云台设置页面，可以通过该页面实现对云台以及相机的远程控制。',
            text5: '除此之外，我们的系统还具备高速数据处理能力，能够在海上飞行目标快速移动的情况下，实现对目标的实时跟踪和定位，保证监测的及时性和灵活性。同时，系统还支持对多个目标的同时跟踪，满足多种监测需求，提高了海上飞行目标监测的效率和精度，通过特殊算法对接收到的数据进行修正，使得航迹结果接近真实。'
          },
          {
            name: '噪声傅里叶变化优化算法',
            src1: require('../../assets/声信号1.png'),
            srcinfo1: require('../../assets/声信号2.png'),
            srcinfo2: require('../../assets/声信号1.png'),
            srcinfo3: '',
            srcinfo4: '',
            srcinfo5: '',
            srcinfo6: '',
            text: '首先，我们的声信号处理软件拥有强大的音频处理功能，包括音频录制、播放、剪辑、混音等多种功能。用户可以通过软件对音频文件进行编辑和处理，调整音频效果、去除噪音、增强声音质量，从而实现音频文件的优化和定制化处理。其次，软件具有智能的声音识别和分析功能，可以对音频数据进行实时识别和分类。通过先进的语音识别技术和情感分析算法，软件能够识别语音内容、情绪色彩，甚至分析说话人的情绪状态，为用户提供更深层次的声音信息和洞察。此外，声信号处理软件还支持音频数据的批量处理和自动化操作，帮助用户提高工作效率和节省时间成本。',
            text1: '首先，我们的声信号处理软件拥有强大的音频处理功能，包括音频录制、播放、剪辑、混音等多种功能。用户可以通过软件对音频文件进行编辑和处理，调整音频效果、去除噪音、增强声音质量，从而实现音频文件的优化和定制化处理。其次，软件具有智能的声音识别和分析功能，可以对音频数据进行实时识别和分类。通过先进的语音识别技术和情感分析算法，软件能够识别语音内容、情绪色彩，甚至分析说话人的情绪状态，为用户提供更深层次的声音信息和洞察。此外，声信号处理软件还支持音频数据的批量处理和自动化操作，帮助用户提高工作效率和节省时间成本。',
            text2: '用户可以设置处理流程和参数，实现批量处理音频文件，快速完成大规模音频数据的处理和分析任务。最重要的是，我们的软件注重用户体验和易用性，界面简洁直观，操作便捷灵活，适用于各类用户群体。无论是音频编辑师、语音分析师还是普通用户，都可以轻松上手并发挥软件的强大功能，实现声音数据的处理和利用。',
            text3: '',
            text4: '',
            text5: ''
          }
        ]
      },
      caseArea4: {
        caseAreaName: 'AI大数据·私域GTP·国产化',
        imageUrl: require('../../assets/titlebackground4.png'),
        caseAreaList: [
          {
            name: 'AI大数据',
            src1: require('../../assets/船舶采集1.png'),
            srcinfo1: require('../../assets/船舶采集2.png'),
            srcinfo2: require('../../assets/船舶采集3.png'),
            srcinfo3: require('../../assets/船舶采集1.png'),
            srcinfo4: '',
            srcinfo5: '',
            srcinfo6: '',
            text: '信息感知技术是智能船舶技术的基础，在船舶航行过程中，实时采集各类设备数据为船舶自动航行提供数据基础。积累下的数据亦为后续数据分析打下基础。',
            text1: '信息感知技术是智能船舶技术的基础，在船舶航行过程中，实时采集各类设备数据为船舶自动航行提供数据基础。积累下的数据亦为后续数据分析打下基础。',
            text2: '随着智能船舶数据的积累，通过大数据分析，推进仿真模型演进，并将分析结果用于辅助船舶设计。通过大数据分析不同航速不同纵倾角的船舶总阻力系数曲线。在给定航速下存在着最佳纵倾角度 θ ，此时船舶的阻力最小。',
            text3: '系统支持远程监控和管理功能，管理者可以通过互联网随时随地访问系统，监测船舶运行状态并进行远程操作。系统可生成各类报表和数据统计图表，帮助管理者进行数据分析和决策，并支持报表分享和导出功能。',
            text4: '',
            text5: ''
          },
          {
            name: '私有化GPT',
            src1: require('../../assets/私域AI1.png'),
            srcinfo1: require('../../assets/私域AI2.png'),
            srcinfo2: require('../../assets/私域AI3.png'),
            srcinfo3: require('../../assets/私域AI1.png'),
            srcinfo4: '',
            srcinfo5: '',
            srcinfo6: '',
            text: '私域AI软件是我们公司最新推出的一款创新产品，它致力于为企业提供强大的数据管理与营销工具，帮助企业实现精细化运营和精准营销。私域AI软件整合了人工智能技术、大数据分析和营销自动化功能，为企业提供了全方位的解决方案。首先，私域AI软件可以帮助企业进行用户画像分析和数据挖掘，通过对用户行为、偏好和消费习惯等数据的深度分析，帮助企业更好地了解自身用户群体，从而实现精准营销和个性化推荐。同时，软件还能够实时监控用户行为和数据变化，为企业提供及时的决策支持。',
            text1: '私域AI软件是我们公司最新推出的一款创新产品，它致力于为企业提供强大的数据管理与营销工具，帮助企业实现精细化运营和精准营销。私域AI软件整合了人工智能技术、大数据分析和营销自动化功能，为企业提供了全方位的解决方案。首先，私域AI软件可以帮助企业进行用户画像分析和数据挖掘，通过对用户行为、偏好和消费习惯等数据的深度分析，帮助企业更好地了解自身用户群体，从而实现精准营销和个性化推荐。同时，软件还能够实时监控用户行为和数据变化，为企业提供及时的决策支持。',
            text2: '其次，私域AI软件还具有营销自动化的功能，可以帮助企业实现自动化的客户互动和营销活动管理。通过智能的营销引擎，软件能够根据用户特征和行为，自动生成个性化的营销内容和推送方案，提高营销效率和转化率。同时，软件还支持多渠道的营销活动管理，包括短信、邮件、App推送等多种方式，帮助企业实现全渠道的精准营销。',
            text3: '此外，私域AI软件还注重数据安全和隐私保护，采用先进的数据加密和隐私保护技术，确保用户数据的安全性和合规性。软件还支持多级权限管理和数据访问控制，帮助企业建立健全的数据治理体系，保障用户数据的安全和隐私。',
            text4: '',
            text5: ''
          },
          {
            name: 'MatLab工业软件国产化',
            src1: require('../../assets/国产化4.png'),
            srcinfo1: require('../../assets/国产化4.png'),
            srcinfo2: require('../../assets/国产化2.png'),
            srcinfo3: require('../../assets/国产化3.png'),
            srcinfo4: require('../../assets/国产化1.png'),
            srcinfo5: '',
            srcinfo6: '',
            text: '应国家卡脖子问题解决之背景，自研部分关键数学函数的国产化替代。',
            text1: '应国家卡脖子问题解决之背景，自研部分关键数学函数的国产化替代。',
            text2: '自编函数，原理如下：极大似然法也是统计推断的基本方法，它利用一个含有未知参数的变量来表示观测值的联合概率，定义该概率为最值函数，求解问题，一般通过求导法即可得到未知参数值。对于可靠性试验数据，若获得了i=i~r个完全样本，明确得到r个样本的失效时间，ii=1~n-r个非完全样本，得到n-r个非完全样本的失效时间，可以得到完全样本数据和非完全样本数据似然函数L(β,γ)为L(β,γ)=∏_(𝑖=1)^𝑟  β/γ 〖(𝑡_𝜈𝑖/γ)〗^(β−1) 𝑒^(〖−(𝑡_𝜈𝑖/γ)〗^β )∙∏_(𝑖𝑖=1)^(𝑛−𝑟) 𝑒^(〖−(𝑡_𝜏𝑖/γ)〗^β )其中𝑡_𝜈𝑖 、𝑡_𝜏𝑖分别为完全样本数据和非完全样本数据。为方便求解对似然函数取对数，整理后得到lnL(β,γ)=γlnβ−γβlnγ+(β−1) ∏_(𝑖=1)^𝑟 𝑙𝑛𝑡_𝑣 (𝑖)−1/γ^β  ∑_(𝑖=1)^𝑛 𝑡_𝑖^β对此方程的两个未知变量进行取偏导并为0，即可得到方程组，对方程组进行整理并尽可能的消除变量，即可得到极大似然法的求解方程{_(   γ/β+∑_(𝑖=1)^𝑟 𝑙𝑛𝑡_𝑣𝑖−𝑟 (∑_(𝑖=1)^𝑛 𝑡_𝑖^β 𝑙𝑛𝑡_𝑖)/(∑_(𝑖=1)^𝑛 𝑡_𝑖^β )=0)^(   −𝑟γ^β  + ∑_(𝑖=1)^𝑛 𝑡_𝑖^β  =0 )通过求解此方程组得到似然函数。',
            text3: '极大似然法也是统计推断的基本方法，它利用一个含有未知参数的变量来表示观测值的联合概率，定义该概率为最值函数，求解问题，一般通过求导法即可得到未知参数值。对此方程的两个未知变量进行取偏导并为0，即可得到方程组，对方程组进行整理并尽可能的消除变量，即可得到极大似然法的求解方程 ',
            text4: '专为工程技术人员和科研学者设计的软件系统，旨在提供与MATLAB相媲美的功能和性能，同时充分符合国内用户的需求和使用习惯。该软件采用国产化技术，结合了国内科研院校和企业的创新成果，为用户提供高效、稳定的工程计算和数据分析平台。',
            text5: '',
            text6: ''
          }
        ]
      },
      caseArea5: {
        caseAreaName: '智慧医疗·农业·工厂',
        imageUrl: require('../../assets/titlebackground5.png'),
        caseAreaList: [
          {
            name: '体感仿真医疗康复系统',
            src1: require('../../assets/case_ara1.png'),
            src2: require('../../assets/case_ara3.png'),
            srcinfo1: require('../../assets/case_ara3.png'),
            srcinfo2: require('../../assets/case_ara4.png'),
            srcinfo3: require('../../assets/case_ara5.png'),
            srcinfo4: require('../../assets/case_ara1.png'),
            srcinfo5: require('../../assets/case_ara2.png'),
            srcinfo6: require('../../assets/case_ara6.png'),
            text: 'ARA医疗康复训练系统是和动作捕捉传感器结合使用的一款病患行走康复软件，需要结合传感器一起使用。',
            text1: 'ARA医疗康复训练系统是和动作捕捉传感器结合使用的一款病患行走康复软件，需要结合传感器一起使用。',
            text2: '通过5.8G WIFI无线传输方式实时传输到电脑主机，实现对游戏引擎中虚拟人物的运动控制，同时进行数据可视化处理分析。',
            text3: '随着科技的迅速发展，康复医学虚拟仿真软件正成为医学教育领域的一股强大力量。这些软件不仅为学生提供了模拟真实医院问诊情境的机会，而且通过创新的教学设计，突破了传统医学教育的局限，为学生提供了更加丰富、实用的学习体验。',
            text4: '在康复医学虚拟仿真软件中，学生可以身临其境地参与模拟真实医院问诊情境。患者一旦听到呼叫，便进入诊室，而诊室医生则对患者展开全面评估。这种真实感的模拟场景让学生感觉仿佛置身于实际的医疗环境中，增强了学习的参与感和专注度。在进行问诊前，软件通过思考题的形式引导学生思考，帮助他们全面掌握问诊需要涉及的各个方面内容。这种交互式的学习方式不仅使学生在模拟中能够更好地应对实际的问诊情境，还培养了他们分析和解决问题的能力。',
            text5: '根据人物设定的任务目标,达到一定程度后进入任务系统,根据提示文字进行相应的任务动作,完成动作后会在APP端显示实时完成的进度。',
            text6: '通过硬件实时发送数据给客户端3D人物模型的骨骼点和真人动作达到一致,左边实时根据人物完成的信息进行变化,可以达到一个客户端同时检测多套设备的功能。'
          },
          {
            name: '温室种植智能监控系统',
            src1: require('../../assets/case_wenshi1.png'),
            src2: require('../../assets/case_wenshi2.png'),
            srcinfo1: require('../../assets/case_wenshi3.png'),
            srcinfo2: require('../../assets/case_wenshi4.png'),
            srcinfo3: require('../../assets/case_wenshi5.png'),
            srcinfo4: require('../../assets/case_wenshi6.png'),
            srcinfo5: require('../../assets/case_wenshi1.png'),
            srcinfo6: '',
            text: '采用光照、空气温湿度、土壤温湿度等传感器对温室网棚培育环境进行实时感知，通过无线信息传输节点将数字信号传输到系统后台，经过服务器处理后形成图形化显示输出。',
            text1: '采用光照、空气温湿度、土壤温湿度等传感器对温室网棚培育环境进行实时感知，通过无线信息传输节点将数字信号传输到系统后台，经过服务器处理后形成图形化显示输出。',
            text2: '系统提供各种统计功能并支持数据导出，当环境指标超标时能够自动开启和关闭风机、电磁阀、遮阳板等设备以实现智能化。',
            text3: '土壤温湿度采集、存储、传输和管理于一体的土壤墒情自动监测系统。可以灵活布置土壤水分传感器，也可将传感器布置在不同的深度，测量剖面土壤水分情况。可根据不同的应用场合，配置不同的产品。',
            text4: '通过空气温度、湿度、光照、光合有效辐射传感器采集信息可以及时掌握大田生长情况，当大田因这些因素生长受限，用户可快速反应，采取应急措施。',
            text5: '通过雨量、风速、风向、气压传感器可收集大量气象信息，当这些信息超出正常值范围，可及时采取防范措施，减轻自然灾害带来的损失。检测土壤温度、水分是为了实现合理灌溉，杜绝水源浪费和大量灌溉导致的土壤养分流失。检测土壤PH值等信息，是为了全面检测土壤养分含量，准确指导水田合理施肥，提高产量，避免由于过量施肥导致的环境问题。'
          },
          {
            name: '智慧工厂仿真系统',
            src1: require('../../assets/智慧工厂1.gif'),
            srcinfo1: require('../../assets/智慧工厂1.png'),
            srcinfo2: require('../../assets/智慧工厂2.png'),
            srcinfo3: require('../../assets/智慧工厂6.png'),
            srcinfo4: require('../../assets/智慧工厂3.png'),
            srcinfo5: require('../../assets/智慧工厂4.png'),
            srcinfo6: require('../../assets/智慧工厂5.png'),
            text: '某钢智慧工厂系统通过物联网技术、互联网技术等智能方式构建以厂区安全、设施、交通、效能、环境为管理核心的综合管理指挥平台，实现协同一体的操作环境。',
            text1: '某钢智慧工厂系统通过物联网技术、互联网技术等智能方式构建以厂区安全、设施、交通、效能、环境为管理核心的综合管理指挥平台，实现协同一体的操作环境。',
            text2: '某化工厂工业自动化系统，以各类传感器采集到的数据参数为依托，结合生产流程实际需要，以数字信息化手段，实现生产流程的自动化控制，同时对生产全流程各环节做到可视化监控。',
            text3: '',
            text4: '该系统整合了虚拟现实、人工智能、大数据分析等前沿技术，能够模拟和优化工厂生产流程、设备运行状态，以及人员物料协同情况，帮助企业实现智能化生产管理。',
            text5: '通过智慧工厂仿真系统，用户可以进行生产线优化、排程调度、资源利用分析等工作，提高生产效率，降低成本，增强工厂整体竞争力。同时，系统还支持实时监控和预警功能，帮助企业及时发现和解决生产中的问题，助力工厂实现智慧化转型升级。',
            text6: ''
          }
        ]
      },
      caseArea6: {
        caseAreaName: '应用级客户定制开发',
        imageUrl: require('../../assets/titlebackground6.png'),
        caseAreaList: [
          {
            name: '【ERP领域代表案例】定制开发',
            src1: require('../../assets/erp1.png'),
            srcinfo1: require('../../assets/erp1.png'),
            srcinfo2: require('../../assets/erp2.png'),
            srcinfo3: require('../../assets/erp3.png'),
            srcinfo4: require('../../assets/erp5.png'),
            srcinfo5: '',
            srcinfo6: '',
            text: '系统升级项目，由原来的C/S结构转变为B/S结构。涵盖财务、人事、生产、供应商等管理机能。',
            text1: '系统升级项目，由原来的C/S结构转变为B/S结构。涵盖财务、人事、生产、供应商等管理机能。',
            text2: '主页看板，直观状况一目了然',
            text3: '报表浏览打印',
            text4: '财务管理模块',
            text5: ''
          },
          {
            name: '【MES领域代表案例】定制开发',
            src1: require('../../assets/ems1.png'),
            srcinfo1: require('../../assets/ems1.png'),
            srcinfo2: require('../../assets/ems2.png'),
            srcinfo3: require('../../assets/ems3.png'),
            srcinfo4: '',
            srcinfo5: '',
            srcinfo6: '',
            text: '定义停机类别，定义故障原因，定义故障分类。跟踪设备故障记录，停机记录。统计故障分类，分析故障原因。设备月度、年度运行报表。',
            text1: '定义停机类别，定义故障原因，定义故障分类。跟踪设备故障记录，停机记录。统计故障分类，分析故障原因。设备月度、年度运行报表。',
            text2: '多种模式点检计划的设定可供用户选择：如：计划，实际，固定的天和周，固定日期，固定日期按月。',
            text3: '允许用户为资产填加图片。',
            text4: '',
            text5: ''
          },
          {
            name: '【餐饮连锁领域解决方案】定制开发',
            src1: require('../../assets/餐饮平台1.png'),
            srcinfo1: require('../../assets/餐饮平台2.png'),
            srcinfo2: require('../../assets/餐饮平台3.png'),
            srcinfo3: require('../../assets/餐饮平台4.png'),
            srcinfo4: require('../../assets/餐饮平台5.png'),
            srcinfo5: '',
            srcinfo6: '',
            text: '业界繁荣店铺的店长的管理方式，简单来说，就是P(计划)·D(执行)·C(验证)A(改善）的循环运用。系统汇集了优秀店长们管理方法的精髓。通过快速顺畅地运转P·D·C·A，提高业务品质，增加经营利润：明确计划的目标、配置适当的人员，安排适当的订货和库存，降低不必要的损失。基于计划的执行情况进行业务评价(数据分析功能），对店铺的业绩作出迅速简明评价，并将评价结果灵活地应用到之后的促销活动制定当中。',
            text1: '业界繁荣店铺的店长的管理方式，简单来说，就是P(计划)·D(执行)·C(验证)A(改善）的循环运用。系统汇集了优秀店长们管理方法的精髓。通过快速顺畅地运转P·D·C·A，提高业务品质，增加经营利润：明确计划的目标、配置适当的人员，安排适当的订货和库存，降低不必要的损失。基于计划的执行情况进行业务评价(数据分析功能），对店铺的业绩作出迅速简明评价，并将评价结果灵活地应用到之后的促销活动制定当中。',
            text2: '此餐饮管理解决方案整合了先进的信息技术和餐饮管理理念，为餐饮企业提供全方位的管理支持。首先，我们的系统涵盖了从订单管理、库存管理、人员管理到财务管理等各个方面，实现了全流程的信息化管理，帮助餐饮企业实现对经营数据的全面监控和分析。',
            text3: '其次，我们的解决方案支持移动端应用，使得餐厅管理更加灵活和便捷。通过移动端应用，餐厅管理人员可以随时随地进行订单处理、库存查看、人员安排等工作，提高了工作效率和管理的实时性。同时，顾客也可以通过移动端应用进行预订、点餐等操作，提升了顾客就餐的便利性和体验感。',
            text4: '此外，我们的解决方案还融合了智能化技术，包括人脸识别支付、智能推荐菜单等功能，为顾客和餐厅带来更便捷、个性化的服务。通过对顾客行为和偏好的分析，我们的系统能够为餐厅提供定制化的服务方案，提升了顾客满意度和忠诚度。',
            text5: ''
          },
          {
            name: '【移动端APP小程序】定制开发',
            src1: require('../../assets/小程序1.png'),
            srcinfo1: require('../../assets/小程序1.png'),
            srcinfo2: require('../../assets/小程序2.png'),
            srcinfo3: require('../../assets/小程序4.png'),
            srcinfo4: require('../../assets/小程序7.png'),
            srcinfo5: '',
            srcinfo6: '',
            text: '移动端APP小程序开发，如畜牧领域降本增效提质小程序；政府公益项目APP等.',
            text1: '移动端APP小程序开发，如畜牧领域降本增效提质小程序；政府公益项目APP等.',
            text2: '通过软件，管理人员可以实时查看畜牧生产数据、库存情况、销售情况等信息，帮助他们做出更准确的决策，提升生产效率和产品质量。',
            text3: '每个月的考核则是对畜牧企业各部门绩效的评估和总结，通过设定考核指标和评分标准，可以客观地评价各部门的工作表现。考核结果将以企业微信消息的形式推送给相关人员，及时通知他们自己和团队的表现，激励大家共同努力，持续提升绩效水平。通过结合企业微信消息推送功能，我们的软件实现了信息的即时传递和沟通，让管理者和员工都能随时了解企业动态和个人工作表现，促进团队之间的合作和协调。这种便捷的沟通方式不仅提高了工作效率，还增强了团队凝聚力和向心力。',
            text4: '以公益为出发点，通过扁平快的上报模式，使城市中人人可以改变生活环境，共筑舒适化，人性化的城市环境。并针对文化，时尚，体育，健康等领域聚集共同价值观的认同者。协助政府，建立城市征信标准，最大化地为用户提供多元化的城市服务，智能化的公共服务。',
            text5: ''
          }
        ]
      },
      ClassicCase: []
    }
  },
  created() {
    this.ClassicCase = [
      this.caseArea1,
      this.caseArea2,
      this.caseArea3,
      this.caseArea4,
      this.caseArea5,
      this.caseArea6
    ]
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    // 如果浏览器宽度小于等于1220
    if (window.innerWidth <= 1024) {
      this.headerStyle = false
    } else {
      this.headerStyle = true
    }
    const type = this.$route.query.type
    const targetElement = document.querySelector('#areaPart' + type)
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop - 150,
        behavior: 'smooth'
      })
    }
  },
  beforeDestroy() {
    // 在组件销毁前移除事件监听器，以防止内存泄漏
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      // 如果浏览器宽度小于等于1220就是手机平板宽度
      if (window.innerWidth <= 1024) {
        this.headerStyle = false
      } else {
        this.headerStyle = true
      }
    },
    goToInfo(caseOne, areaName, index, caseIndex) {
      const id = '' + (++index) + (++caseIndex)
      this.$router.push({ path: '/ClassicCaseDetail', query: { caseOne, areaName, id }}).catch(err => console.log(err))
    },
    goToback() {
      this.infoFlag = false
    },
    gotoClassicCaseDetail(e) {
      this.$router.push({ path: '/ClassicCaseDetail', query: { item: e, areaName: this.areaName }}).catch(err => console.log(err))
    },
    goTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // 平滑滚动效果，可选
      })
    },
    goToArea(index) {
      const targetElement = document.querySelector('#areaPart' + (index + 1))
      if (targetElement) {
        window.scrollTo({
          top: targetElement.offsetTop - 100,
          behavior: 'smooth'
        })
      }
    }
  }
}
</script>
<style lang="scss">
@media screen and (min-width: 1024px) {
  .case_container1{
    padding-top: 70px;
    padding-bottom: 10px;
    background-color: #f2f2f2;
    .container2{
      max-width: 1320px;
      width: 100%;
      margin: 0 auto;
      .backButton {
        position: fixed;
        bottom: 0vh;
        right: 9vw;
        z-index: 105;
        // width: 150px; /* 菜单的宽度，根据需要调整 */
        .button {
          // height: 100px;
          // width: 100px;
          // background-color: black;
          display: flex;
          align-items: center;
          justify-content: center;
          color: black;
          // border-radius: 50px;
          // line-height: 100px;
          font-size: 20px;
          background-color: white;
          box-shadow: 0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15);
          border-radius: 5px;
          padding: 2px;
          img {
            width: 30px;
          }
        }
        .button:hover {
          cursor: pointer;
        }
      }
      .title {
        font-size: 48px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        padding-bottom: 30px;
        text-align:left;
      }
      .line{
        height: 1px;
        background: #585858;
        // visibility: visible;
        // animation-duration: 2s;
        // animation-delay: 0s;
        // animation-name: fadeInLeft3;
      }
      .area_Navigation_bar {
        .area_ul {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          align-items: center;
          li {
            // width: 100%;
            .area_Navigation_title {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 25px;
              font-size: 16px;
              font-family: Source Han Sans CN;
              border-radius: 12.5px;
              transition: all 0.3s ease;
              padding: 0px 5px;
            }
            .area_Navigation_title:hover {
              // background-color: #5bacf2;
              color: #fff;
              background-color: #2196f3;
              box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(33, 33, 33, 0.4);
            }
          }
        }
      }
      .area_out {
        padding: 20px 0 0 0;
        ul {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
          align-content: space-around;
          li {
            width: 100%;
            margin: auto;
            .areaTitle {
              margin: 0 auto;
              align-items: center;
              font-size: 20px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              line-height: 100px;
              background-size: cover;
              background-position: center;
              // a {
              //   color: #666;
              //   outline: none;
              //   text-decoration: none;
              //   .area_pic {
              //     width: 100%;
              //     overflow: hidden;
              //     img {
              //       width: 100%;
              //       height: 100%;
              //     }
              //   }
              //   .area_txtbox {
              //     height: 185px;
              //     padding: 20px;
              //     padding-top: 40px;
              //     transition: all 700ms ease;
              //     border-bottom: solid 1px #dedede;
              //     .tit {
              //       color: #2b2f39;
              //       font-size: 24px;
              //       line-height: 30px;
              //       height: 30px;
              //       overflow: hidden;
              //       text-align: left;
              //       transition: font-size 0.3s;
              //     }
              //     .txt {
              //       color: #5e677e;
              //       font-size: 18px;
              //       line-height: 30px;
              //       height: 30px;
              //       margin-top: 30px;
              //       overflow: hidden;
              //       text-align: left;
              //       transition: font-size 0.3s;
              //     }
              //   }
              // }
            }
            .areaContainer {
              .caseItem{
                margin-top: 10vh;
                margin-bottom: 15vh;
                .caseContainer {
                  width: 80%;
                  margin: 0 auto;
                  position: relative;
                  margin-bottom: 30px;
                  border-radius: 5px;
                  color: rgba(0, 0, 0, 0.87);
                  background: #fff;
                  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
                  .img_show {
                    width: 90%;
                    z-index: 100;
                    position: relative;
                    border-radius: 5px;
                    transform: translate(0, -5vh);
                    transition: transform 0.3s ease;
                    box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
                  }
                  .img_shadow{
                    z-index: 99;
                    filter: blur(20px);
                    position: absolute;
                    width: 91%;
                    left: 0;
                    right: 0;
                    margin: auto;
                    background-size: cover;
                    transition: opacity .45s;
                    transform: translate(0, -4vh);
                  }
                  .card_text{
                    padding: 15px 50px;
                    .caseTitle {
                      margin: 0 auto;
                      align-items: center;
                      color: #3C4858;
                      text-align: left;
                      font-size: 30px;
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      img{
                        width: 15px;
                        height: 24px;
                        margin-right: 15px;
                      }
                    }
                    p {
                      color: #999;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                      display: -webkit-box;
                      -webkit-box-orient: vertical;
                      -webkit-line-clamp: 4;
                      font-weight: 400;
                      font-size: 1.0rem;
                      white-space: pre-line;
                    }
                  }
                }
                .caseContainer:hover {
                  cursor: pointer;
                  .img_show:hover {
                    transform: scale(1.05);
                  }
                  .img_show:hover + .img_shadow {
                    transform: scale(0);
                    filter: blur(0px);
                  }
                }
              }
            }
          }
          li:hover {
            // border-color: #415385;
            // box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
            // cursor: pointer;
            // .tit {
            //   color: #2b2f39;
            //   font-size: 24px;
            //   line-height: 30px;
            //   height: 30px;
            //   overflow: hidden;
            //   text-align: center;
            //   transform: scale(1.2);
            // }
            // .txt {
            //   color: #2b2f39;
            //   font-size: 18px;
            //   line-height: 30px;
            //   height: 30px;
            //   overflow: hidden;
            //   text-align: center;
            //   transform: scale(1.2);
            // }
          }
        }
      }
      .goTopButton {
        font-size: 20px;
        margin-top: -50px;
      }
      .goTopButton:hover {
        cursor: pointer; /* 鼠标指针形状为手型 */
      }
      .caseInfo {
        overflow: hidden;
        ul {
          padding-top: 20px;
          list-style: none;
          li {
            // float: left;
            padding-top: 100px;
            margin-top: 100px;
            margin: 0 auto;
            position: relative;
            width: 820px;
            .item{
              position: relative;
              .pic1 {
                position: relative;
                z-index: 2;
                // height: 200px;
                // width: 340px;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  transform-origin: top center;
                  transition: all 500ms ease;
                }
              }
              .pic2 {
                position: absolute;
                z-index: 1;
                left: 0px;
                top: 0px;
                width: 820px;
                // height: 200px;
                // width: 340px;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  transform-origin: top center;
                  transition: all 500ms ease;
                  transform:scale(0)
                }
              }
            }
            .til {
              color: #363636;
              margin: 0px auto;
              text-align: center;
              font-size: 24px;
              // line-height: 30px;
              // height: 30px;
              // width: 340px;
              overflow: hidden;
              transition: all 500ms ease;
            }
            .item:hover{
              cursor: pointer;
              .pic1 {
                img {
                  transform:scale(0)
                }
              }
              .pic2 {
                img {
                  transform:scale(1)
                }
              }
              .til {
                transform:scale(0)
              }
            }
          }
        }
      }
    }
  }
  .case{
      margin: 40px 0px;
  }
  .case_pic {
      transition: box-shadow 0.3s ease-in-out; /* 添加过渡效果 */
  }
  .case_pic:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* 添加阴影 */
  }
}
@media screen and (max-width: 1023px) {
  .case_container1{
    // padding-top: 70px;
    padding-bottom: 10px;
    background-color: #f2f2f2;
    .container2{
      max-width: 1320px;
      width: 100%;
      margin: 0 auto;
      .backButton {
        position: fixed;
        bottom: 20vh;
        right: 15vw;
        width: 150px; /* 菜单的宽度，根据需要调整 */
        .button {
          height: 100px;
          width: 100px;
          background-color: black;
          color: #fff;
          border-radius: 50px;
          line-height: 100px;
          font-size: 30px;
        }
        .button:hover {
          cursor: pointer;
        }
      }
      .title {
        font-size: 48px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        padding-bottom: 30px;
        text-align:left;
      }
      .line{
        height: 1px;
        background: #585858;
        // visibility: visible;
        // animation-duration: 2s;
        // animation-delay: 0s;
        // animation-name: fadeInLeft3;
      }
      .area_Navigation_bar {
        display: none;
        .area_ul {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          align-items: center;
          li {
            // width: 100%;
            .area_Navigation_title {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 25px;
              font-size: 16px;
              font-family: Source Han Sans CN;
              border-radius: 12.5px;
              transition: all 0.3s ease;
              padding: 0px 5px;
            }
            .area_Navigation_title:hover {
              // background-color: #5bacf2;
              color: #fff;
              background-color: #2196f3;
              box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(33, 33, 33, 0.4);
            }
          }
        }
      }
      .area_out {
        // padding: 20px 0 0 0;
        ul {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
          align-content: space-around;
          margin-top: 50px;
          li {
            width: 100%;
            margin: auto;
            .areaTitle {
              margin: 0 auto;
              align-items: center;
              font-size: 20px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              line-height: 50px;
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
              margin: 0px 10px;
              // width: 100%;
              // a {
              //   color: #666;
              //   outline: none;
              //   text-decoration: none;
              //   .area_pic {
              //     width: 100%;
              //     overflow: hidden;
              //     img {
              //       width: 100%;
              //       height: 100%;
              //     }
              //   }
              //   .area_txtbox {
              //     height: 185px;
              //     padding: 20px;
              //     padding-top: 40px;
              //     transition: all 700ms ease;
              //     border-bottom: solid 1px #dedede;
              //     .tit {
              //       color: #2b2f39;
              //       font-size: 24px;
              //       line-height: 30px;
              //       height: 30px;
              //       overflow: hidden;
              //       text-align: left;
              //       transition: font-size 0.3s;
              //     }
              //     .txt {
              //       color: #5e677e;
              //       font-size: 18px;
              //       line-height: 30px;
              //       height: 30px;
              //       margin-top: 30px;
              //       overflow: hidden;
              //       text-align: left;
              //       transition: font-size 0.3s;
              //     }
              //   }
              // }
            }
            .areaContainer {
              .caseItem{
                margin-top: 3vh;
                margin-bottom: 4vh;
                .caseContainer {
                  width: 80%;
                  margin: 0 auto;
                  position: relative;
                  margin-bottom: 30px;
                  border-radius: 5px;
                  color: rgba(0, 0, 0, 0.87);
                  background: #fff;
                  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
                  .img_show {
                    width: 90%;
                    z-index: 100;
                    position: relative;
                    border-radius: 5px;
                    transform: translate(0, -1vh);
                    transition: transform 0.3s ease;
                    box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
                  }
                  .img_shadow{
                    z-index: 99;
                    filter: blur(20px);
                    position: absolute;
                    width: 91%;
                    left: 0;
                    right: 0;
                    margin: auto;
                    background-size: cover;
                    transition: opacity .45s;
                    // transform: translate(0, -4vh);
                  }
                  .card_text{
                    padding: 10px 25px;
                    .caseTitle {
                      margin: 0 auto;
                      align-items: center;
                      color: #3C4858;
                      text-align: left;
                      font-size: 15px;
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      img{
                        width: 8px;
                        height: 13px;
                        margin-right: 8px;
                      }
                    }
                    p {
                      color: #999;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      text-align: left;
                      display: -webkit-box;
                      -webkit-box-orient: vertical;
                      // -webkit-line-clamp: 2;
                      font-weight: 400;
                      font-size: 15px;
                      white-space: pre-line;
                    }
                  }
                }
                .caseContainer:hover {
                  cursor: pointer;
                  .img_show:hover {
                    transform: scale(1.05);
                  }
                  .img_show:hover + .img_shadow {
                    transform: scale(0);
                    filter: blur(0px);
                  }
                }
              }
            }
          }
          li:hover {
            // border-color: #415385;
            // box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
            // cursor: pointer;
            // .tit {
            //   color: #2b2f39;
            //   font-size: 24px;
            //   line-height: 30px;
            //   height: 30px;
            //   overflow: hidden;
            //   text-align: center;
            //   transform: scale(1.2);
            // }
            // .txt {
            //   color: #2b2f39;
            //   font-size: 18px;
            //   line-height: 30px;
            //   height: 30px;
            //   overflow: hidden;
            //   text-align: center;
            //   transform: scale(1.2);
            // }
          }
        }
      }
      .goTopButton {
        display: flex;
        font-size: 20px;
        margin-top: -15px;
        text-align: center;
        justify-content: center;
      }
      .goTopButton:hover {
        cursor: pointer; /* 鼠标指针形状为手型 */
      }
      .caseInfo {
        overflow: hidden;
        ul {
          padding-top: 20px;
          list-style: none;
          li {
            // float: left;
            padding-top: 100px;
            margin-top: 100px;
            margin: 0 auto;
            position: relative;
            width: 820px;
            .item{
              position: relative;
              .pic1 {
                position: relative;
                z-index: 2;
                // height: 200px;
                // width: 340px;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  transform-origin: top center;
                  transition: all 500ms ease;
                }
              }
              .pic2 {
                position: absolute;
                z-index: 1;
                left: 0px;
                top: 0px;
                width: 820px;
                // height: 200px;
                // width: 340px;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  transform-origin: top center;
                  transition: all 500ms ease;
                  transform:scale(0)
                }
              }
            }
            .til {
              color: #363636;
              margin: 0px auto;
              text-align: center;
              font-size: 24px;
              // line-height: 30px;
              // height: 30px;
              // width: 340px;
              overflow: hidden;
              transition: all 500ms ease;
            }
            .item:hover{
              cursor: pointer;
              .pic1 {
                img {
                  transform:scale(0)
                }
              }
              .pic2 {
                img {
                  transform:scale(1)
                }
              }
              .til {
                transform:scale(0)
              }
            }
          }
        }
      }
    }
  }
  .case{
      margin: 40px 0px;
  }
  .case_pic {
      transition: box-shadow 0.3s ease-in-out; /* 添加过渡效果 */
  }
  .case_pic:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* 添加阴影 */
  }
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import main from '../views/main/index.vue'
import homePage from '../views/homePage/index.vue'
import aboutUs from '../views/aboutUs/index.vue'
import product from '../views/product/index.vue'
import solution from '../views/solution/index.vue'
import solutionDetail from '../views/solution/solutionDetail/index.vue'
import productIntroduction from '../views/product/productIntroduction/index.vue'
import classicCase from '../views/classicCase/index.vue'
import classicCaseDetail from '../views/classicCase/classicCaseDetail/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: main,
    hidden: true,
    redirect: '/HomePage', // 新增 redirect 配置
    children: [
      {
        path: '/HomePage',
        component: homePage,
        hidden: true
      },
      {
        path: '/AboutUs',
        component: aboutUs,
        hidden: true
      },
      {
        path: '/Product',
        component: product,
        hidden: true
      },
      {
        path: '/Solution',
        component: solution,
        hidden: true
      },
      {
        path: '/ClassicCase',
        component: classicCase,
        hidden: true
      },
      {
        path: '/SolutionDetail',
        component: solutionDetail,
        hidden: true
      },
      {
        path: '/ProductIntroduction',
        component: productIntroduction,
        hidden: true
      },
      {
        path: '/ClassicCaseDetail',
        component: classicCaseDetail,
        hidden: true
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router

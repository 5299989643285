<template>
  <div class="main_class">
    <div>
      <div><img class="banner_class" src="../../assets/banner1.png"/></div>
      <div class="home_product">
        <div class="home_product_text">
          <div class="home_prouct_en">ZHUYAOCHANPIN</div>
          <div class="home_prouct_zh">主要产品</div>
          <div style="border: 2px solid #2156AE; width: 80px; margin: 0px auto;margin-top: 20px;"></div>
        </div>
        <div class="display_product">
          <div class="position_div">
            <div class="product_item" @click="goTo('/Product', '1')"><img class="product_item_img1" src="../../assets/首页product1.png"/><div class="product_item_title">图像识别拍摄设备</div></div>
            <div class="product_item" @click="goTo('/Product', '2')"><img class="product_item_img2" src="../../assets/首页product2.png"/><div class="product_item_title">步行辅助设备</div></div>
          </div>
          <div class="position_div">
            <div class="product_item" @click="goTo('/Product', '3')"><img class="product_item_img3" src="../../assets/首页product3.png"/><div class="product_item_title">VR体感动作捕捉设备</div></div>
            <div class="product_item" @click="goTo('/Product', '4')"><img class="product_item_img4" src="../../assets/首页product4.png"/><div class="product_item_title">信号处理设备</div></div>
          </div>
        </div>
      </div>
      <div><img class="banner_class" src="../../assets/banner2.png"/></div>
      <div class="home_product">
        <div class="home_case_text">
          <div class="home_prouct_en">JIEJUEFANGAN</div>
          <div class="home_prouct_zh">解决方案</div>
          <div style="border: 2px solid #2156AE; width: 80px; margin: 0px auto;margin-top: 20px;"></div>
        </div>
        <div class="diaplay_icons">
          <div class="position_div">
            <div class="icon_item" @click="goTo('/Solution', '3')"><img class="icon_item_img1" src="../../assets/icon_大数据1.png"/><div class="icon_item_title">大数据</div></div>
            <div class="icon_item" @click="goTo('/Solution', '1')"><img class="icon_item_img2" src="../../assets/icon_虚拟现实2.png"/><div class="icon_item_title">虚拟现实</div></div>
            <div class="icon_item" @click="goTo('/Solution', '6')"><img class="icon_item_img3" src="../../assets/icon_国产化3.png"/><div class="icon_item_title">国产化</div></div>
          </div>
          <div class="position_div">
            <div class="icon_item" @click="goTo('/Solution', '5')"><img class="icon_item_img4" src="../../assets/icon_私有GPT4.png"/><div class="icon_item_title">私有GPT</div></div>
            <div class="icon_item" @click="goTo('/Solution', '2')"><img class="icon_item_img5" src="../../assets/icon_智慧海洋5.png"/><div class="icon_item_title">智慧海洋</div></div>
            <div class="icon_item" @click="goTo('/Solution', '4')"><img class="icon_item_img6" src="../../assets/icon_智慧农业6.png"/><div class="icon_item_title">智慧农业</div></div>
          </div>
        </div>
      </div>
      <div><img class="banner_class" src="../../assets/banner3.png"/></div>
      <div class="home_case_text">
        <div class="home_prouct_en">DIANXINGANLI</div>
        <div class="home_prouct_zh">典型案例</div>
        <div style="border: 2px solid #2156AE; width: 80px; margin: 0px auto;margin-top: 20px;"></div>
      </div>
      <div class="home_product2">
        <img src="../../assets/首页-典型案例.png" @click="goTo('/ClassicCase', '0')" style="cursor: pointer;">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  components: {
  },
  data() {
    return {
      // header样式
      headerStyle: true,
      currentIndex: 0,
      images: [
        // { src: require('../../assets/main1.png') },
        // { src: require('../../assets/main2.png') },
        // { src: require('../../assets/main3.png') },
        // { src: require('../../assets/main4.png') },
        // { src: require('../../assets/main5.png') }
      ]
    }
  },
  mounted() {
  },
  methods: {
    goTo(pathVal, typeNumber) {
      this.$router.push({ path: pathVal, query: { type: typeNumber }}).catch(err => console.log(err))
    }
  }
}
</script>
<style lang="scss">
@media screen and (min-width: 1024px) {
  .main_class {
    background-color: #E0DFE7;
    .banner_class {
      width: 100%;
    }
    .display_product {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      flex-wrap: nowrap;
      .position_div {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        .product_item {
          background-color: white;
          margin: 0px 50px 100px 50px;
          cursor: pointer;
          // padding: 50px 50px 50px 50px;
          .product_item_title {
            padding: 20px 0px;
            font-weight: bold;
            font-size: 20px;
          }
          img {
            width: 100%;
          }
        }
      }
    }
    .diaplay_icons {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      .position_div {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        .icon_item {
          // background-color: aqua;
          margin: 0px 50px;
          padding: 0px 50px;
          padding-bottom: 100px;
          cursor: pointer;
          img {
            margin-bottom: 50px;
            width: 100%;
          }
          .icon_item_title {
            padding: 10px 0px;
            font-weight: bold;
            font-size: 20px;
          }
        }
      }
    }
  }
  .list {
    position: relative;
    max-width: 100vw;
    margin: 0 auto;
  }
  .my-swipe1 {
    width: 100%;
    height: 90vh;
  }
  .home_product {
    // background: #fff;
    // margin-top: 50px;
    width: 100%;
    overflow: hidden;
    img {
      margin-bottom: 100px
    }
  }
  .home_solution {
    background: #fff;
    margin-top: 0;
    width: 100%;
    overflow: hidden;
    background-image: url('../../assets/solutionBackground.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .home_product_contain {
    width: 1200px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  .home_product_text {
    margin: 60px 0px;
  }
  .home_product_text h3 {
    color: #333;
    font-size: 36px;
    font-weight: 500;
    text-align: center;
    border-bottom: 2px solid;
    padding: 20px 0px;
  }
  .home_product_text h5 {
    font-size: 10px;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    color: #333;
    margin: 0;
  }
  .home_case_text {
    margin: 60px 0px;
  }
  .home_case_text h3{
    color: #333;
    font-size: 36px;
    font-weight: 500;
    text-align: center;
    border-bottom: 2px solid;
    padding: 20px 0px;
  }
  .home_case_text h5{
    font-size: 18px;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    color: black;
    margin: 0;
  }
  .row1_col {
    width: 25%;
  }
  .row2_col {
    width: 25%;
  }
  .home_product_img {
    width: 130px;
    height: 130px;
    margin-left: auto;
    margin-right: auto;
    transition: transform 0.3s ease;
  }
  .home_product_img:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
  .home_product_per_title {
    text-align: center;
    font-size: 20px;
    font-family: PingFangSC-Medium;
    color: rgba(51,51,51,1);
    margin: 0 10px;
  }
  .home_case_per_title {
    text-align: center;
    font-size: 20px;
    font-family: PingFangSC-Medium;
    color: #fff;
    margin: 0 10px;
  }
  .home_product_row1 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 120px;
  }
  .home_product_row2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 90px;
    padding-bottom: 120px;
  }
  .background-container1 {
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    background-position: center;
  }
  .home_prouct_en {
    color: #333;
    font-size: 17px;
    font-weight: 500;
    text-align: center
  }
  .home_prouct_zh {
    color: #333;
    font-size: 36px;
    font-weight: 500;
    text-align: center;
  }
  .home_product2 {
    img {
      margin-bottom: 50px
    }
  }
}

/* 当视口宽度小于等于1220时生效 */
@media screen and (max-width: 1023px) {
  /* 样式规则 */
  .main_class {
    background-color: #E0DFE7;
    .banner_class {
      width: 100%;
    }
    .display_product {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      .product_item {
        background-color: white;
        margin: 0px 50px 50px 50px;
        // padding: 50px 50px 50px 50px;
        img {
          width: 100%;
        }
        .product_item_title {
          padding: 20px 0px;
          font-weight: bold;
          font-size: 20px;
        }
      }
    }
    .diaplay_icons {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      // margin: 0 20%;
      .icon_item {
        // background-color: aqua;
        margin: 0px 20px;
        // padding: 0px 50px;
        padding-bottom: 50px;
        .icon_item_title {
          padding: 10px 0px;
          font-weight: bold;
          font-size: 20px;
        }
      }
    }
  }
  .list {
    position: relative;
    max-width: 100vw;
    margin: 0 auto;
  }
  .my-swipe1 {
    width: 100%;
    height: 90vh;
  }
  .home_product2 {
    // background: #fff;
    // margin-top: 50px;
    width: 100%;
    overflow: hidden;
    img {
      margin-bottom: 50px;
      width: 100%;
    }
  }
  .home_solution {
    background: #fff;
    margin-top: 0;
    width: 100%;
    overflow: hidden;
    background-image: url('../../assets/solutionBackground.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .home_product_contain {
    width: 1200px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  .home_product_text {
    margin: 20px 0px;
  }
  .home_product_text h3 {
    color: #333;
    font-size: 36px;
    font-weight: 500;
    text-align: center;
    border-bottom: 2px solid;
    padding: 20px 0px;
  }
  .home_product_text h5 {
    font-size: 10px;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    color: #333;
    margin: 0;
  }
  .home_case_text {
    margin: 20px 0px;
  }
  .home_case_text h3{
    color: #333;
    font-size: 36px;
    font-weight: 500;
    text-align: center;
    border-bottom: 2px solid;
    padding: 20px 0px;
  }
  .home_case_text h5{
    font-size: 18px;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    color: black;
    margin: 0;
  }
  .row1_col {
    width: 25%;
  }
  .row2_col {
    width: 25%;
  }
  .home_product_img {
    width: 130px;
    height: 130px;
    margin-left: auto;
    margin-right: auto;
    transition: transform 0.3s ease;
  }
  .home_product_img:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
  .home_product_per_title {
    text-align: center;
    font-size: 20px;
    font-family: PingFangSC-Medium;
    color: rgba(51,51,51,1);
    margin: 0 10px;
  }
  .home_case_per_title {
    text-align: center;
    font-size: 20px;
    font-family: PingFangSC-Medium;
    color: #fff;
    margin: 0 10px;
  }
  .home_product_row1 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 120px;
  }
  .home_product_row2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 90px;
    padding-bottom: 120px;
  }
  .background-container1 {
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    background-position: center;
  }
  .home_prouct_en {
    color: #333;
    font-size: 17px;
    font-weight: 500;
    text-align: center
  }
  .home_prouct_zh {
    color: #333;
    font-size: 36px;
    font-weight: 500;
    text-align: center;
  }
}
</style>

<template>
  <div class="div_class">
    <div v-if="headerStyle" class="header_class"  :class="[ presentRouter === '/HomePage' || presentRouter === '/Solution' || presentRouter === '/AboutUs'? backgroundClass ?'background_transparent':'background_default':'background_default']">
      <div class="header_div">
        <ul class="header_ul">
          <li class="header_logo"><div><img alt="logo" src="../../assets/华夏腾飞pc_logo-白色.png" @click="goTo($event, '/HomePage')"></div></li>
          <li :class="['border_button_class', presentRouter === '/HomePage'?'hovered':'']" @click="goTo($event, '/HomePage')">
            <button>公司首页</button>
          </li>
          <li :class="['border_button_class', presentRouter === '/ClassicCase'?'hovered':'']" @click="goTo($event, '/ClassicCase', '0')" @mouseenter="showSubMenu('3')" @mouseleave="hideSubMenu('3')">
            <button>典型案例</button>
            <ul v-if="showFlag3 === true" class="submenu3">
              <li @mouseenter="showSubMenuSub('1')" @mouseleave="hideSubMenuSub('1')" @click="handleInnerClick($event, '/ClassicCase', '1')">虚拟现实
                <ul v-if="case1 === true">
                  <li @click="handleInnerClick($event, '/ClassicCase', '11')">海洋三维态势实时监控平台</li>
                  <li @click="handleInnerClick($event, '/ClassicCase', '12')">科考船舶VR</li>
                </ul>
              </li>
              <li @mouseenter="showSubMenuSub('2')" @mouseleave="hideSubMenuSub('2')" @click="handleInnerClick($event, '/ClassicCase', '2')">海洋试验
                <ul v-if="case2 === true">
                  <li @click="handleInnerClick($event, '/ClassicCase', '21')">水上竞优仿真对抗系统</li>
                  <li @click="handleInnerClick($event, '/ClassicCase', '22')">水下攻防复盘系统</li>
                </ul>
              </li>
              <li @mouseenter="showSubMenuSub('3')" @mouseleave="hideSubMenuSub('3')" @click="handleInnerClick($event, '/ClassicCase', '3')">先端算法
                <ul v-if="case3 === true">
                  <li @click="handleInnerClick($event, '/ClassicCase', '31')">轨迹曲线拟合算法</li>
                  <li @click="handleInnerClick($event, '/ClassicCase', '32')">图形图像识别算法</li>
                  <li @click="handleInnerClick($event, '/ClassicCase', '33')">噪声傅里叶变化优化算法</li>
                </ul>
              </li>
              <li @mouseenter="showSubMenuSub('4')" @mouseleave="hideSubMenuSub('4')" @click="handleInnerClick($event, '/ClassicCase', '4')">AI大数据・私域GPT・国产化
                <ul v-if="case4 === true">
                  <li @click="handleInnerClick($event, '/ClassicCase', '41')">AI大数据</li>
                  <li @click="handleInnerClick($event, '/ClassicCase', '42')">私有化GPT</li>
                  <li @click="handleInnerClick($event, '/ClassicCase', '43')">MatLab工业软件国产化</li>
                </ul>
              </li>
              <li @mouseenter="showSubMenuSub('5')" @mouseleave="hideSubMenuSub('5')" @click="handleInnerClick($event, '/ClassicCase', '5')">智慧医疗・农业・工厂
                <ul v-if="case5 === true">
                  <li @click="handleInnerClick($event, '/ClassicCase', '51')">体感仿真医疗康复系统</li>
                  <li @click="handleInnerClick($event, '/ClassicCase', '52')">温室种植智能监控系统</li>
                  <li @click="handleInnerClick($event, '/ClassicCase', '53')">智慧工厂仿真系统</li>
                </ul>
              </li>
              <li @mouseenter="showSubMenuSub('6')" @mouseleave="hideSubMenuSub('6')" @click="handleInnerClick($event, '/ClassicCase', '6')">应用级客户定制开发
                <ul v-if="case6 === true">
                  <li @click="handleInnerClick($event, '/ClassicCase', '61')">【ERP领域代表案例】定制开发</li>
                  <li @click="handleInnerClick($event, '/ClassicCase', '62')">【MES领域代表案例】定制开发</li>
                  <li @click="handleInnerClick($event, '/ClassicCase', '63')">【餐饮连锁领域解决方案】定制开发</li>
                  <li @click="handleInnerClick($event, '/ClassicCase', '64')">【移动端APP小程序】定制开发</li>
                </ul>
              </li>
            </ul>
          </li>
          <li :class="['border_button_class', presentRouter === '/Solution'?'hovered':'']" @click="goTo($event, '/Solution', '0')" @mouseenter="showSubMenu('2')" @mouseleave="hideSubMenu('2')">
            <button>解决方案</button>
            <ul v-if="showFlag2 === true" class="submenu2">
              <li @click="handleInnerClick($event, '/Solution', '1')">虚拟现实</li>
              <li @click="handleInnerClick($event, '/Solution', '2')">智慧海洋</li>
              <li @click="handleInnerClick($event, '/Solution', '3')">大数据</li>
              <li @click="handleInnerClick($event, '/Solution', '4')">智慧农业</li>
              <li @click="handleInnerClick($event, '/Solution', '5')">私有化GPT</li>
              <li @click="handleInnerClick($event, '/Solution', '6')">国产替代</li>
            </ul>
          </li>
          <li :class="['border_button_class', presentRouter === '/Product'?'hovered':'']" @click="goTo($event, '/Product', '0')" @mouseenter="showSubMenu('1')" @mouseleave="hideSubMenu('1')">
            <button>主要产品</button>
            <ul v-if="showFlag1 === true" class="submenu1">
              <li @click="handleInnerClick($event, '/Product', '1')">图像识别拍摄</li>
              <li @click="handleInnerClick($event, '/Product', '2')">步行辅助仪</li>
              <li @click="handleInnerClick($event, '/Product', '3')">VR动作捕捉体感</li>
              <li @click="handleInnerClick($event, '/Product', '4')">声信号处理</li>
            </ul>
          </li>
          <li :class="['border_button_class', presentRouter === '/ClassicCaseDetail'?'hovered':'']" @click="toClassicCaseDetail">
            <button>助船宝</button>
          </li>
          <li :class="['border_button_class', presentRouter === '/AboutUs'?'hovered':'']" @click="goTo($event, '/AboutUs', '0')" @mouseenter="showSubMenu('4')" @mouseleave="hideSubMenu('4')">
            <button>关于我们</button>
            <ul v-if="showFlag4 === true" class="submenu4">
              <li @click="handleInnerClick($event, '/AboutUs', '1')">企业概述</li>
              <li @click="handleInnerClick($event, '/AboutUs', '2')">企业理念</li>
              <li @click="handleInnerClick($event, '/AboutUs', '3')">发展经历</li>
              <li @click="handleInnerClick($event, '/AboutUs', '4')">企业资质 </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div v-else class="move_header" >
      <div :class="['right_header_class', moveBackgroundClass ? 'moveHeaderbackground_white':'moveHeaderbackground_black']">
        <div class="img_logo">
          <img alt="logo" :src="[moveBackgroundClass ? (subRouter ? require('../../assets/华夏腾飞logo-白色.png'):require('../../assets/华夏腾飞logo-黑色.png')):require('../../assets/华夏腾飞logo-白色.png')]" @click="goTo($event, '/HomePage')">
        </div>
        <div @click="openrightMenu" class="img_menu">
          <i :class="['el-icon-s-operation', moveBackgroundClass ? (subRouter ? 'moveHeadericonColor_white': 'moveHeadericonColor_black'):'moveHeadericonColor_white']"/>
        </div>
      </div>
      <div v-show="rightMenu" class="menu" @click="closeMenu($event)">
        <ul style="max-height: 100%;background-color: white;">
          <li @click="goTo($event, '/HomePage')">
            <div class="noArrow"><h3>公司首页</h3></div>
          </li>
          <li @click="moveShowSubMenu($event, '/ClassicCase')">
            <div class="haveArrow"><h3><a @click="goTo($event, '/ClassicCase', '0')">典型案例</a></h3></div>
            <ul v-if="moveSubMenu3 === true" class="submenu">
              <li @mouseenter="showSubMenuSub('1')" @mouseleave="hideSubMenuSub('1')" @click="handleInnerClick($event, '/ClassicCase', '1')">虚拟现实</li>
              <li @mouseenter="showSubMenuSub('2')" @mouseleave="hideSubMenuSub('2')" @click="handleInnerClick($event, '/ClassicCase', '2')">海洋试验</li>
              <li @mouseenter="showSubMenuSub('3')" @mouseleave="hideSubMenuSub('3')" @click="handleInnerClick($event, '/ClassicCase', '3')">先端算法</li>
              <li @mouseenter="showSubMenuSub('4')" @mouseleave="hideSubMenuSub('4')" @click="handleInnerClick($event, '/ClassicCase', '4')">AI大数据・私域GPT・国产化</li>
              <li @mouseenter="showSubMenuSub('5')" @mouseleave="hideSubMenuSub('5')" @click="handleInnerClick($event, '/ClassicCase', '5')">智慧医疗・农业・工厂</li>
              <li @mouseenter="showSubMenuSub('6')" @mouseleave="hideSubMenuSub('6')" @click="handleInnerClick($event, '/ClassicCase', '6')">应用级客户定制开发</li>
            </ul>
          </li>
          <li @click="moveShowSubMenu($event, '/Solution')">
            <div class="haveArrow"><h3><a @click="goTo($event, '/Solution', '0')">解决方案</a></h3></div>
            <ul v-if="moveSubMenu2 === true" class="submenu">
              <li @click="handleInnerClick($event, '/Solution', '1')">虚拟现实</li>
              <li @click="handleInnerClick($event, '/Solution', '2')">智慧海洋</li>
              <li @click="handleInnerClick($event, '/Solution', '3')">大数据</li>
              <li @click="handleInnerClick($event, '/Solution', '4')">智慧农业</li>
              <li @click="handleInnerClick($event, '/Solution', '5')">私有化GPT</li>
              <li @click="handleInnerClick($event, '/Solution', '6')">国产替代</li>
            </ul>
          </li>
          <li @click="moveShowSubMenu($event, '/Product')">
            <div class="haveArrow"><h3><a @click="goTo($event, '/Product', '0')">主要产品</a></h3></div>
            <ul v-if="moveSubMenu1 === true" class="submenu">
              <li @click="handleInnerClick($event, '/Product', '1')">图像识别拍摄</li>
              <li @click="handleInnerClick($event, '/Product', '2')">步行辅助仪</li>
              <li @click="handleInnerClick($event, '/Product', '3')">VR动作捕捉体感</li>
              <li @click="handleInnerClick($event, '/Product', '4')">声信号处理</li>
            </ul>
          </li>
          <li @click="moveShowSubMenu($event, '/AboutUs')">
            <div class="haveArrow"><h3><a @click="goTo($event, '/AboutUs', '0')">关于我们</a></h3></div>
            <ul v-if="moveSubMenu4 === true" class="submenu">
              <li @click="handleInnerClick($event, '/AboutUs', '1')">企业概述</li>
              <li @click="handleInnerClick($event, '/AboutUs', '2')">企业理念</li>
              <li @click="handleInnerClick($event, '/AboutUs', '3')">发展经历</li>
              <li @click="handleInnerClick($event, '/AboutUs', '4')">企业资质 </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div id="scro" class="body_class">
      <router-view />
    </div>
    <div class="footer_class">
      <div class="footer_link">
        <div class="link_part">
          <!-- <ul>
            <li class="footer_title"><h5>主要产品</h5></li>
            <li class="footer_tiltle_item" @click="goTo($event, '/Product', '1')"><h5>图像识别拍摄</h5></li>
            <li class="footer_tiltle_item" @click="goTo($event, '/Product', '2')"><h5>步行辅助仪</h5></li>
            <li class="footer_tiltle_item" @click="goTo($event, '/Product', '3')"><h5>VR动作捕捉体感</h5></li>
            <li class="footer_tiltle_item" @click="goTo($event, '/Product', '4')"><h5>声信号处理</h5></li>
          </ul> -->
          <ul>
            <li class="footer_title"><h5>典型案例</h5></li>
            <li class="footer_tiltle_item" @click="goTo($event, '/ClassicCase', '1')"><h5>虚拟现实</h5></li>
            <li class="footer_tiltle_item" @click="goTo($event, '/ClassicCase', '2')"><h5>海洋试验</h5></li>
            <li class="footer_tiltle_item" @click="goTo($event, '/ClassicCase', '3')"><h5>先端算法</h5></li>
            <li class="footer_tiltle_item" @click="goTo($event, '/ClassicCase', '4')"><h5>AI大数据・私域GPT・国产化</h5></li>
            <li class="footer_tiltle_item" @click="goTo($event, '/ClassicCase', '5')"><h5>智慧医疗・农业・工厂</h5></li>
            <li class="footer_tiltle_item" @click="goTo($event, '/ClassicCase', '6')"><h5>应用级客户定制开发</h5></li>
          </ul>
        </div>
        <div class="link_part">
          <ul>
            <li class="footer_title"><h5>解决方案</h5></li>
            <li class="footer_tiltle_item" @click="goTo($event, '/Solution', '1')"><h5>虚拟现实</h5></li>
            <li class="footer_tiltle_item" @click="goTo($event, '/Solution', '2')"><h5>智慧海洋</h5></li>
            <li class="footer_tiltle_item" @click="goTo($event, '/Solution', '3')"><h5>大数据</h5></li>
            <li class="footer_tiltle_item" @click="goTo($event, '/Solution', '4')"><h5>智慧农业</h5></li>
            <li class="footer_tiltle_item" @click="goTo($event, '/Solution', '5')"><h5>私有化GPT</h5></li>
            <li class="footer_tiltle_item" @click="goTo($event, '/Solution', '6')"><h5>国产替代</h5></li>
          </ul>
        </div>
        <div class="link_part">
          <!-- <ul>
            <li class="footer_title"><h5>典型案例</h5></li>
            <li class="footer_tiltle_item" @click="goTo($event, '/ClassicCase', '1')"><h5>虚拟现实</h5></li>
            <li class="footer_tiltle_item" @click="goTo($event, '/ClassicCase', '2')"><h5>海洋试验</h5></li>
            <li class="footer_tiltle_item" @click="goTo($event, '/ClassicCase', '3')"><h5>先端算法</h5></li>
            <li class="footer_tiltle_item" @click="goTo($event, '/ClassicCase', '4')"><h5>AI大数据・私域GPT・国产化</h5></li>
            <li class="footer_tiltle_item" @click="goTo($event, '/ClassicCase', '5')"><h5>智慧医疗・农业・工厂</h5></li>
            <li class="footer_tiltle_item" @click="goTo($event, '/ClassicCase', '6')"><h5>应用级客户定制开发</h5></li>
          </ul> -->
          <ul>
            <li class="footer_title"><h5>主要产品</h5></li>
            <li class="footer_tiltle_item" @click="goTo($event, '/Product', '1')"><h5>图像识别拍摄</h5></li>
            <li class="footer_tiltle_item" @click="goTo($event, '/Product', '2')"><h5>步行辅助仪</h5></li>
            <li class="footer_tiltle_item" @click="goTo($event, '/Product', '3')"><h5>VR动作捕捉体感</h5></li>
            <li class="footer_tiltle_item" @click="goTo($event, '/Product', '4')"><h5>声信号处理</h5></li>
          </ul>
        </div>
        <div class="link_part">
          <ul>
            <li class="footer_title"><h5>关于我们</h5></li>
            <li class="footer_tiltle_item" @click="goTo($event, '/AboutUs', '1')"><h5>企业概述</h5></li>
            <li class="footer_tiltle_item" @click="goTo($event, '/AboutUs', '2')"><h5>企业理念</h5></li>
            <li class="footer_tiltle_item" @click="goTo($event, '/AboutUs', '3')"><h5>发展经历</h5></li>
            <li class="footer_tiltle_item" @click="goTo($event, '/AboutUs', '4')"><h5>企业资质</h5></li>
          </ul>
        </div>
      </div>
      <div class="footer_divider"></div>
      <div v-if="headerStyle" class="footer_companyInfo">
        <div class="footer_left">
          <div class="footer_text">
            电话：186-4280-2998
          </div>
          <div class="footer_text">
            ICP备案号：琼ICP备2023014434号
          </div>
          <div class="footer_text">
            Copyright © 2024 海南乾助科技有限公司
          </div>
          <div class="footer_text">
            地址：海南省三亚市崖州湾科技城雅布伦产业园3号楼34-C-85
          </div>
        </div>
        <!-- <div class="footer_center">
          <div class="footer_text">
            ICP备案号：辽ICP备19008422号
          </div>
          <div class="footer_text">
            Copyright © 2024 大连华夏腾飞科技有限公司
          </div>
          <div class="footer_text">
            地址：大连市 高新园区广贤路135号 智业大厦B2座9楼
          </div>
        </div> -->
        <!-- <div class="footer_right">
          <img src="../../assets/wx.png"/>
        </div> -->
      </div>
      <div v-else class="footer_companyInfo">
        <div class="footer_left">
          <div class="footer_text">
            电话：186-4280-2998
          </div>
          <div class="footer_text">
            ICP备案号：琼ICP备2023014434号
          </div>
          <div class="footer_text">
            Copyright © 2024 海南乾助科技有限公司
          </div>
          <div class="footer_text">
            地址：海南省三亚市崖州湾科技城雅布伦产业园3号楼34-C-85
          </div>
        </div>
        <!-- <div class="footer_right">
          <img src="../../assets/wx.png"/>
        </div> -->
        <!-- <div class="footer_center">
          <div class="footer_text">
            ICP备案号：辽ICP备19008422号
          </div>
          <div class="footer_text">
            Copyright © 2024 大连华夏腾飞科技有限公司
          </div>
          <div class="footer_text">
            地址：大连市 高新园区广贤路135号 智业大厦B2座9楼
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IndexMain',
  components: {
  },
  watch: {
    '$route'(to, from) {
      const toPath = to.path // 更新激活的路由路径
      this.presentRouter = to.path
      if (toPath === '/ProductIntroduction' || toPath === '/ClassicCaseDetail') {
        this.subRouter = true
      } else {
        this.subRouter = false
      }
    }
  },
  data() {
    return {
      // header样式
      headerStyle: true,
      // 左侧菜单
      rightMenu: false,
      // 当前router
      presentRouter: '/HomePage',
      // 二级路径标志
      subRouter: false,
      parameter: '',
      isActive: false,
      showFlag1: false,
      showFlag2: false,
      showFlag3: false,
      showFlag4: false,
      case1: false,
      case2: false,
      case3: false,
      case4: false,
      case5: false,
      case6: false,
      moveSubMenu1: false,
      moveSubMenu2: false,
      moveSubMenu3: false,
      moveSubMenu4: false,
      backgroundClass: true,
      moveBackgroundClass: true,
      caseOne: {
        name: '助船宝',
        src1: require('../../assets/船舶vr1.png'),
        srcinfo1: require('../../assets/船舶vr2.png'),
        srcinfo2: require('../../assets/船舶vr3.png'),
        srcinfo3: require('../../assets/船舶vr4.png'),
        srcinfo4: require('../../assets/船舶vr5.png'),
        srcinfo5: '',
        srcinfo6: '',
        text: '助船宝利用VR和3D仿真技术，对科考船、商务船等进行全方位立体展示，解决身在异地不能莅临现场的用户的问题。船舶介绍、设备介绍、联系方式等功能齐全，导航地图、图片导览等浏览模式可以快速定位到想了解的位置，交互友好。',
        text1: '助船宝利用VR和3D仿真技术，对科考船、商务船等进行全方位立体展示，解决身在异地不能莅临现场的用户的问题。船舶介绍、设备介绍、联系方式等功能齐全，导航地图、图片导览等浏览模式可以快速定位到想了解的位置，交互友好。',
        text2: '助船宝VR平台利用先进的虚拟现实技术，通过绘制的三维模型和全景展示，将用户带入其中国。用户可以在虚拟环境中360度自由观察船舶的外部结构和内部设施，深入了解船舶的各项功能和性能指标。',
        text3: '用户可以通过VR全景图更真实地感受科研双体船的内外部环境，包括船舱布局、设备配置等，有助于用户更准确地了解船舶情况。',
        text4: '虚拟3D模型。虚拟和现实相结合，双重体验。',
        text5: ''
      }
    }
  },
  created() {
  },
  mounted() {
    // // 添加resize事件监听器
    // window.addEventListener('resize', this.handleResize)
    // 添加滚动条监听事件
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleResize)
    // 关闭左侧菜单
    // this.rightMenu = false
    // 如果浏览器宽度小于等于1220就是手机平板宽度
    // if (window.innerWidth <= 1220) {
    //   this.headerStyle = false
    // } else {
    //   this.headerStyle = true
    // }
    this.handleResize()
  },
  beforeDestroy() {
    // 在组件销毁前移除事件监听器，以防止内存泄漏
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('scroll', this.handleScroll)
  },
  computed: {
  },
  methods: {
    toClassicCaseDetail() {
      this.$router.push({ path: '/ClassicCaseDetail', query: { caseOne: this.caseOne, areaName: '虚拟现实', id: '12' }}).catch(err => console.log(err))
    },
    // 监听浏览器宽度
    handleResize() {
      // 关闭左侧菜单
      this.rightMenu = false
      // 如果浏览器宽度小于等于1220就是手机平板宽度
      if (window.innerWidth <= 1024) {
        this.headerStyle = false
      } else {
        this.headerStyle = true
      }
    },
    // 打开右侧菜单
    openrightMenu() {
      this.rightMenu = !this.rightMenu
      this.isActive = !this.isActive
    },
    closeMenu(event) {
      event.stopPropagation()
      this.rightMenu = false
    },
    // 点击菜单跳转
    goTo(event, pathVal, typeNumber) {
      if (!this.headerStyle) {
        event.stopPropagation()
      }
      this.showFlag1 = false
      this.showFlag2 = false
      this.showFlag3 = false
      this.showFlag4 = false
      this.case1 = false
      this.case2 = false
      this.case3 = false
      this.case4 = false
      this.case5 = false
      this.case6 = false
      this.moveSubMenu1 = false
      this.moveSubMenu2 = false
      this.moveSubMenu3 = false
      this.moveSubMenu4 = false
      const currentRoute = this.$route.fullPath
      const targetRoute = `${pathVal}?type=${typeNumber}`
      if (currentRoute !== targetRoute) {
        this.presentRouter = pathVal
        this.parameter = typeNumber
        console.log(pathVal)
        this.$router.push({ path: pathVal, query: { type: typeNumber }}).catch(err => console.log(err))
        this.rightMenu = false
      }
    },
    handleInnerClick(event, route, param) {
      console.log('3333')
      // 阻止外层点击方法触发
      event.stopPropagation()
      // 处理内层点击逻辑
      this.goTo(event, route, param)
    },
    showSubMenu(index) {
      if (index === '1') {
        this.showFlag1 = true
      } else if (index === '2') {
        this.showFlag2 = true
      } else if (index === '3') {
        this.showFlag3 = true
      } else if (index === '4') {
        this.showFlag4 = true
      }
    },
    moveShowSubMenu(event, param) {
      event.stopPropagation()
      // 在这里添加你的处理逻辑
      if (param === '/Product') {
        this.moveSubMenu1 = !this.moveSubMenu1
        this.moveSubMenu2 = false
        this.moveSubMenu3 = false
        this.moveSubMenu4 = false
      } else if (param === '/Solution') {
        this.moveSubMenu2 = !this.moveSubMenu2
        this.moveSubMenu1 = false
        this.moveSubMenu3 = false
        this.moveSubMenu4 = false
      } else if (param === '/ClassicCase') {
        this.moveSubMenu3 = !this.moveSubMenu3
        this.moveSubMenu1 = false
        this.moveSubMenu2 = false
        this.moveSubMenu4 = false
      } else if (param === '/AboutUs') {
        this.moveSubMenu4 = !this.moveSubMenu4
        this.moveSubMenu1 = false
        this.moveSubMenu2 = false
        this.moveSubMenu3 = false
      }
    },
    hideSubMenu(index) {
      if (index === '1') {
        this.showFlag1 = false
      } else if (index === '2') {
        this.showFlag2 = false
      } else if (index === '3') {
        this.showFlag3 = false
      } else if (index === '4') {
        this.showFlag4 = false
      }
    },
    showSubMenuSub(index) {
      console.log('111')
      if (index === '1') {
        this.case1 = true
      } else if (index === '2') {
        this.case2 = true
      } else if (index === '3') {
        this.case3 = true
      } else if (index === '4') {
        this.case4 = true
      } else if (index === '5') {
        this.case5 = true
      } else if (index === '6') {
        this.case6 = true
      }
    },
    hideSubMenuSub(index) {
      if (index === '1') {
        this.case1 = false
      } else if (index === '2') {
        this.case2 = false
      } else if (index === '3') {
        this.case3 = false
      } else if (index === '4') {
        this.case4 = false
      } else if (index === '5') {
        this.case5 = false
      } else if (index === '6') {
        this.case6 = false
      }
    },
    handleScroll(event) {
      if (this.headerStyle) {
        if (this.presentRouter === '/HomePage' || this.presentRouter === '/Solution' || this.presentRouter === '/AboutUs') {
          const scrollPosition = window.scrollY
          if (scrollPosition !== 0) {
            this.backgroundClass = false
          } else {
            this.backgroundClass = true
          }
        }
      } else {
        const scrollPosition = window.scrollY
        if (scrollPosition !== 0) {
          this.moveBackgroundClass = false
        } else {
          this.moveBackgroundClass = true
        }
      }
    }
  }
}
</script>
<style lang="scss">
@media screen and (min-width: 1024px) {
  .div_class {
    background: rgb(250, 250, 250);
    .footer_class {
      background-color: black;
      padding-top: 20px;
      .footer_link {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        align-items: flex-start;
        margin-bottom: 20px;
        ul {
          li {
            text-align: left;
          }
          .footer_title {
            color: #fff;
          }
          .footer_tiltle_item {
            color: #9fa3a6;
          }
          .footer_tiltle_item:hover {
            cursor: pointer;
          }
        }
      }
      .footer_divider {
        width: 100%;
        border-top: 1px solid #9fa3a6; /* 分割线样式 */
      }
      .footer_companyInfo {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        .footer_left {
          margin: 20px 20px;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-evenly;
          align-items: center;
          .footer_text {
            color: #9fa3a6;
            padding: 10px 20px;
            text-align: left;
          }
        }
        .footer_center {
          margin: 20px 20px;
          .footer_text {
            color: #9fa3a6;
            padding: 10px 20px;
            text-align: left;
          }
        }
        .footer_right {
          margin: 20px 20px;
          width: 6%;
          // display: none;
          img {
            width: 100%;
          }
        }
      }
    }
  }
  // header菜单
  .header_class {
    /* 在2秒后执行css */
    // animation: header-change 1s linear 1s forwards;
    width: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 9999999;
    transition: all 0.3s ease;
    .header_div {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      /* align-content: space-around; */
      justify-content: space-evenly;
      .header_ul {
        display: flex;
        width: 1320px;
        list-style-type: none;
        padding: 0;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        height: 60px;
        .header_logo{
          margin-right: 30px;
          height: auto;
          text-align: left;
        }
        li {
          button {
            font-size: 16px;
            color: white;
            font-family: "Microsoft YaHei", "arial";
            background-color: transparent;
            line-height: 35px;
            border: 0px;
            height: 100%;
          }
          button:hover {
            cursor: pointer;
          }
          ul {
            position: absolute;
            background-color: #fff;
            border-radius: 3px;
            padding: 10px 10px;
            li {
              text-align: left;
              padding: 10px;
              font-size: 13px;
              border-radius: 2px;
              white-space: nowrap;
              transition: all  0.3s ease; /* 过渡效果 */
              ul {
                position: absolute;
                background-color: #fff;
                border-radius: 3px;
                padding: 10px 10px;
                left: 200px;
                margin-top: -36px;
                color: #212121;
              }
            }
            li:hover {
              background-color: #212121;
              color: #fff;
              box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(33, 33, 33, 0.4);
            }
          }
        }
        li:hover {
          cursor: pointer;
        }
      }
    }
    .border_button_class {
      height: 98%;
      margin-top: 0 auto;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  // 右侧菜单
  .right_header_class {
    height: 100px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    .img_logo {
      width: 70%;
    }
    .img_menu {
      width: 30%;
      font-size: 30px;
    }
  }
  .border_i_class {
    margin-top: 35px;
    color: black;
    font-size: 30px;
  }
  .border_i_class:hover {
    color: rgb(97, 97, 97);
  }
  .rightMenu_button_class {
    min-width: 100px;
    .el-drawer {
      background-color: rgb(228, 228, 228);
    }
    .el-button {
      border: none;
      background: none;
      color: black;
      font-size: 20px;
      font-weight: 500;
      text-align: left;
      width: 100%;
    }
    //鼠标移入颜色
    .el-button:hover {
      // background-color: #ff7701;
      color: rgb(99, 99, 99);
    }
  }
  .rightMenu_col_class {
    border: 1px solid black;
  }
  .rightMenu_i_class {
    font-size: 39px !important;
  }
  .rightMenu_i_class:hover {
    color: rgb(99, 99, 99);
  }
  .menu {
    position: absolute;
    top: 100px;
    left: 0px;
    width: 100%;
    height: 100%;
    max-height: 100%;
    background-color:  rgba(0, 0, 0, 0.5);
    z-index: 100;
    li{
      padding: 10px;
      border-bottom: solid 1px rgba(179, 179, 179, 0.3);
    }
  }
  .slide-enter-active {
    transition: max-height 1s ease;
  }
  .slide-leave-active {
    transition: max-height 0.5s ease;
  }
  .slide-enter,
  .slide-leave-to {
    max-height: 0;
    overflow: hidden;
  }
  .body_class {
  }
  .footer_p1 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer_p2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer_text1_class {
    display: inline-block;
    // float: left;
  }
  .footer_text2_class {
    display: inline-block;
    // float: left;
  }
  .footer_text3_class {
    display: inline-block;
    // float: left;
  }
  .footer_text4_class {
    display: inline-block;
    // float: left;
  }
  .footer_text_marginTB {
    margin: 20px 0;
  }
  .footer_text_marginLR {
    margin: 0 30px;
  }
  .background_transparent {
    background-color: transparent;
    background-color: rgba(0, 0, 0, 0.3)
  }
  .background_default {
    background-color: black;
    box-shadow: 0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15);
  }
  .hovered {
    border-bottom: 2px solid #fff;
  }
}
/* 当视口宽度小于等于1220时生效 */
@media screen and (max-width: 1023px) {
  /* 样式规则 */
  .body_class {
    // margin-top: 60px;
  }
  .move_header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 110;
    box-shadow: 0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15);
  }
  .right_header_class {
    height: 50px;
    // background-color: white;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    .img_logo {
      width: 70%;
      text-align: left;
      img {
        // width: 50%;
        // margin-left: 15%;
        width: 40%;
        margin-left: 20%;
      }
    }
    .img_menu {
      width: 30%;
      font-size: 30px;
    }
  }
  .rightMenu_button_class {
    min-width: 100px;
    .el-drawer {
      background-color: rgb(228, 228, 228);
    }
    .el-button {
      border: none;
      background: none;
      color: black;
      font-size: 20px;
      font-weight: 500;
      text-align: left;
      width: 100%;
    }
    //鼠标移入颜色
    .el-button:hover {
      // background-color: #ff7701;
      color: rgb(99, 99, 99);
    }
  }
  .rightMenu_col_class {
    border: 1px solid black;
  }
  .rightMenu_i_class {
    font-size: 39px !important;
  }
  .rightMenu_i_class:hover {
    color: rgb(99, 99, 99);
  }
  .menu {
    top: 100px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 101;
    ul {
      li{
        padding: 1px;
        // border-bottom: solid 1px rgba(179, 179, 179, 0.3);
        .noArrow {
          h3 {
            position: relative;
            /* width: 100%; */
            text-align: left;
            padding-left: 20px;
            font-size: 16px;
            font-weight: normal;
          }
        }
        .haveArrow{
          h3 {
            position: relative;
            /* width: 100%; */
            text-align: left;
            padding-left: 20px;
            font-size: 16px;
            font-weight: normal;
          }
          h3::after {
            content: "";
            color: #666;
            font-size: 28px;
            // width: 38px;
            // height: 38px;
            cursor: pointer;
            text-align: center;
            line-height: 38px;
            font-family: arial;
            width: 10px;
            height: 10px;
            right: 20px;
            top: 3px;
            position: absolute;
            transition: all 500ms ease;
            transform: rotate(-45deg);
            border-left: solid 2px #999;
            border-bottom: solid 2px #999;
          }
        }
        ul {
          // transition: all 0.3s ease;
          li {
            padding: 10px;
            text-align: left;
            padding-left: 40px;
            font-size: 15px;
            color: #666;
          }
        }
      }
    }
  }
  .div_class {
    background: rgb(250, 250, 250);
    .footer_class {
      background-color: black;
      padding-top: 20px;
      .footer_link {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: flex-start;
        margin-bottom: 20px;
        justify-content: space-evenly;
        .link_part{
          width: 50%;
          ul {
            margin-left: 5px;
            margin-top: 5px;
            margin-bottom: 5px;
            li {
              text-align: left;
              h5 {
                margin: 10px 0px;
              }
            }
            .footer_title {
              color: #fff;
            }
            .footer_tiltle_item {
              color: #9fa3a6;
            }
            .footer_tiltle_item:hover {
              cursor: pointer;
            }
          }
        }
      }
      .footer_divider {
        width: 100%;
        border-top: 1px solid #9fa3a6; /* 分割线样式 */
      }
      .footer_companyInfo {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        .footer_left {
          margin: 20px 10px;
          // width: 48%;
          .footer_text {
            color: #9fa3a6;
            padding: 10px 0px;
            text-align: left;
          }
        }
        .footer_center {
          margin: 0px 5px;
          width: 94%;
          .footer_text {
            color: #9fa3a6;
            padding: 10px 0px;
            text-align: left;
          }
        }
        .footer_right {
          margin: 20px 0px;
          padding: 10px 0px;
          // width: 48%;
          // visibility: hidden;
          img {
            width: 50%;
          }
        }
      }
    }
  }
  .moveHeaderbackground_black {
    background-color: black;
  }
  .moveHeaderbackground_white {
    background-color: transparent;
  }
  .moveHeadericonColor_black {
    color: black;
  }
  .moveHeadericonColor_white {
    color: #fff;
  }
}
</style>

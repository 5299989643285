<template>
  <div class="solutionDetail_div_class">
    <div class="solutionDetail_title_class">
      {{ solutionDetailData.title }}
    </div>
    <div class="solutionDetail_body_class">
      <div class="solutionDetail_describe_class">
        {{ solutionDetailData.describe }}
      </div>
      <div class="solutionDetail_applicationArea_class">
        {{ solutionDetailData.applicationArea }}
      </div>
      <div class="solutionDetail_applicationArea_text_class">
        {{ solutionDetailData.applicationAreaText }}
      </div>
      <div class="solutionDetail_applicationArea_class">
        {{ solutionDetailData.productFeatures }}
      </div>
      <div class="solutionDetail_applicationArea_text_class">
        {{ solutionDetailData.productFeaturesText }}
      </div>
    </div>
    <div class="solutionDetail_detail_text_class">
      <div>
        {{ solutionDetailData.detailText1 }}
      </div>
      <div>
        {{ solutionDetailData.detailText2 }}
      </div>
      <div>
        {{ solutionDetailData.detailText3 }}
      </div>
      <div>
        {{ solutionDetailData.detailText4 }}
      </div>
      <div>
        {{ solutionDetailData.detailText5 }}
      </div>
    </div>
    <div class="solutionDetail_detail_img_class">
      <img :src="solutionDetailData.imgUrl">
    </div>
    <div style="clear: both;"></div>
  </div>
</template>

<script>
export default {
  name: 'SolutionIndex',
  components: {
  },
  data() {
    return {
      solutionDetailData: {
        title: '',
        describe: '',
        applicationArea: '',
        applicationAreaText: '',
        productFeatures: '',
        productFeaturesText: '',
        detailText1: '',
        detailText2: '',
        detailText3: '',
        detailText4: '',
        detailText5: '',
        imgUrl: ''
      }
    }
  },
  mounted() {
    const info = this.$route.query
    const url = info.imgUrl
    const pathArray = url.split('/')
    const lastElement = pathArray[pathArray.length - 1]
    this.solutionDetailData = {
      title: info.title,
      describe: info.describe,
      applicationArea: info.applicationArea,
      applicationAreaText: info.applicationAreaText,
      productFeatures: info.productFeatures,
      productFeaturesText: info.productFeaturesText,
      detailText1: info.detailText1,
      detailText2: info.detailText2,
      detailText3: info.detailText3,
      detailText4: info.detailText4,
      detailText5: info.detailText5,
      imgUrl: require(`@/assets/${lastElement}`)
    }
  },
  methods: {
  }
}
</script>
<style lang="scss">
.solutionDetail_div_class {
  padding: 2%;
  background-color: #f7f8f9;
}
.solutionDetail_title_class {
  font-size: 50px;
  font-weight: 600;
}
.solutionDetail_body_class {
  padding: 30px 6%;
  line-height: 50px;
}
.solutionDetail_describe_class {
  text-indent: 60px;
  font-size: 30px;
}
.solutionDetail_applicationArea_class {
  float: left;
  margin-top: 10px;
  text-indent: 60px;
  font-size: 30px;
  font-weight: 600;
}
.solutionDetail_applicationArea_text_class {
  margin-top: 10px;
  font-size: 30px;
}
.solutionDetail_detail_text_class {
  float: left;
  font-size: 30px;
  font-weight: 600;
  line-height: 90px;
  margin-top: 10px;
  margin-left: calc(60px + 4%);
}
.solutionDetail_detail_img_class {
  margin-left: 5%;
  float: left;
}
</style>
